<template>
    <v-dialog persistent :model-value="show" max-width="80%" @update:value="$emit('update:show', $event)">
        <v-card tile v-if="show">
            <v-card-title>
                <v-row>
                    <v-col cols="6" style="text-align:left">
                        <span>Umfrage bearbeiten</span>
                    </v-col>
                    <v-col cols="6" style="text-align:right">
                        <v-btn :size="mobile ? '30px': 'default'" icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

                <v-stepper :alt-labels="mobile" v-model="step" non-linear show-actions style="overflow-y:auto">
                    <v-stepper-header>
                        <v-stepper-item value="1" color="blue-darken-2" :complete="surveyDataValid">Allgemeines</v-stepper-item>
                        <v-divider></v-divider>
                        <v-stepper-item value="2" color="blue-darken-2" :complete="questions.length > 0">Fragen</v-stepper-item>
                        <v-divider></v-divider>
                        <v-stepper-item value="3" color="blue-darken-2" >Fertigstellen</v-stepper-item>
                    </v-stepper-header>
                    <v-window>
                    <v-stepper-window>
                        <v-stepper-window-item value="1">
                            <EditForm :editonly="true" v-model:valid="surveyDataValid" :valid="surveyDataValid" :fields="fields" nobuttons />

                            <v-autocomplete v-model="participants" :loading="loading" :disabled="loading" :items="groupMembers" chips label="Teilnehmer" item-title="email" item-value="id" :customFilter="memberFilter" multiple>
                                <template v-slot:chip="data">
                                    <v-chip v-bind="data.props" @click:close="remove(participants, data.item.raw.id)" closable>
                                        <v-avatar prepend>
                                            <v-img :src="'https://teampoll-backend-staging.uni-trier.de/img/avatars/avatar.php?id='+data.item.id"></v-img>
                                        </v-avatar>
                                        {{data.item.raw.email}}
                                    </v-chip>
                                </template>
                                <template v-slot:item="data">
                                    <v-list-item v-bind="data.props" :title="null">
                                        <v-avatar>
                                            <v-img :src="'https://teampoll-backend-staging.uni-trier.de/img/avatars/avatar.php?id='+data.item.id"></v-img>
                                        </v-avatar>
                                        
                                            <v-list-item-title>{{ data.item.raw.firstname }} {{ data.item.raw.lastname }}</v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.raw.email"></v-list-item-subtitle>
                                        
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <v-row v-if="!atLeastOneChipSelected"><p v-html="'Bitte geben Sie mindestens eine teilnehmende Person an.<br><br>'" style="margin-left:16px; color:red; font-size: 12px;"></p></v-row>
                            <v-row v-if="showPendingUsers"><p v-html="pendingUsersText" style="margin-left:16px; color:red; font-size: 12px;"></p></v-row>
                        </v-stepper-window-item>
                        <v-stepper-window-item value="2">
                            <v-row>
                                <v-col>
                                    <h3>Fragen</h3>
                                </v-col>
                            </v-row>
                            <v-row v-show="questions.length == 0">
                                <v-col>
                                    Keine Fragen vorhanden.
                                </v-col>
                            </v-row>
                            <v-row >
                                <v-col>
                                    <v-list>
                                        <v-list-group v-for="(q, i) in questions" :key="i" no-action>
                                            <template v-slot:activator>
                                                
                                                    <v-list-item-title>
                                                        {{q.question}}
                                                        
                                                    </v-list-item-title>
                                                
                                                <v-list-item-action>
                                                    <v-hover v-slot="{ hover }">
                                                        <v-btn :color="hover ? 'error' : 'gray'" @click="removeQuestion(i)" icon><v-icon>mdi-delete</v-icon></v-btn>
                                                    </v-hover>
                                                </v-list-item-action>
                                            </template>
                                            <template v-slot:prepandIcon>
                                                
                                            </template>

                                            <v-list-item v-for="(a, ia) in q.answers" :key="ia">
                                                
                                                    <v-list-item-title v-text="a"></v-list-item-title>
                                                
                                            </v-list-item>
                                        </v-list-group>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-form ref="newquestion" v-model="newquestion.valid" @submit.prevent="addQuestion">
                                <v-card variant="outlined">
                                    <v-card-title>
                                        Frage hinzufügen
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="newquestion.question" required :rules="[v => !!v || 'Frage erforderlich']" type="text" name="question" label="Frage" variant="filled"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col>
                                                    <h3>Antworten</h3>
                                                </v-col>
                                            </v-row>
                                            <v-row v-show="newquestion.answers.length == 0">
                                                <v-col>
                                                    Keine Antworten vorhanden.
                                                </v-col>
                                            </v-row>
                                            <v-row v-for="(a, i) in newquestion.answers" :key="i" dense>
                                                <v-col>
                                                    <v-chip variant="outlined">
                                                        {{a}}
                                                        <v-hover v-slot="{ hover }">
                                                            <v-icon :color="hover ? 'error' : 'gray'" @click="removeAnswer(i)" end size="small">mdi-delete</v-icon>
                                                        </v-hover>
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                            <v-form ref="newanswer" v-model="newquestion.newanswer.valid" @submit.prevent="addAnswer">
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field v-model="newquestion.newanswer.text" required :rules="[v => !!v || 'Antwort erforderlich']" type="text" name="answer" label="Antwort" variant="filled">
                                                            <template v-slot:append>
                                                                <!--v-icon :disabled="!newquestion.newanswer.valid" @click="addAnswer">mdi-plus</v-icon-->
                                                                <v-hover v-slot="{ hover }">
                                                                    <v-icon :color="hover ? 'success' : 'gray'" :disabled="!newquestion.newanswer.valid" @click="addAnswer">mdi-plus</v-icon>
                                                                </v-hover>
                                                            </template>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row justify="end" no-gutters>
                   
                                                <v-btn :disabled="!newquestion.valid || newquestion.answers.length == 0" type="submit"><v-icon start>mdi-plus</v-icon>Frage hinzufügen</v-btn>
                                    
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-stepper-window-item>
                        <v-stepper-window-item value="3">
                            <v-row v-if="step == 2">
                                <v-col :cols="mobile?'12':'4'">
                                    <BoxComponent title="Allgemeine Informationen">
                                        <v-text-field 
                                            v-for="(field) in fields" 
                                            :key="field.var" 
                                            :model-value="field.type == 'select' 
                                                ? field.loadedData.find(i => i[field.select.value] == field.value)[field.select.text] 
                                                : field.type == 'datetime' 
                                                ? new Date(field.value * 1000).toLocaleString().slice(0, -3) 
                                                : field.value == true 
                                                ? 'Ja' 
                                                : field.value == false
                                                ? 'Nein'
                                                : field.value" 
                                            :label="field.text" 
                                            readonly 
                                            disabled
                                            ></v-text-field>

                                    </BoxComponent>
                                </v-col>
                                <v-col>
                                    <BoxComponent title="Fragen">
                                        <v-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Frage
                                                    </th>
                                                    <th class="text-left">
                                                        Antworten
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(question, i) in questions" :key="i">
                                                    <tr>
                                                        <td>{{ question.question }}</td>
                                                        <td>
                                                            <ul>
                                                                <li v-for="answer in question.answers" :key="answer">{{ answer }}</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            </template>
                                        </v-table>
                                    </BoxComponent>
                                </v-col>
                            </v-row>
                        </v-stepper-window-item>
                    </v-stepper-window>
                    <v-row>
                        <v-col :cols="step==2?'4':'6'" style="text-align:left;align-content: end">
                            <v-btn variant="text" disabled @click="step = 0" v-if="step == 0"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                            <v-btn variant="text" @click="step = 0" v-if="step == 1"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                            <v-btn variant="text" @click="step = 1" v-if="step == 2"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                        </v-col>
                        <v-col :cols="step==2?'8':'6'" style="text-align:right">
                            <v-btn variant="text" v-if="step == 0" @click="step = 1" location="right" :disabled="!surveyDataValid || !atLeastOneChipSelected">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                            <v-btn variant="text" v-if="step == 1" @click="step = 2" :disabled="questions.length == 0">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                            <v-btn color="primary" v-if="step == 2" dark class="ma-2 text-subtitle-2 text-capitalize" @click="safeSurvey(false,survey.surveyid)" :loading="loading" :disabled="loading"><v-icon start>mdi-content-save</v-icon> Speichern</v-btn>
                            <v-btn color="primary" v-if="step == 2" dark class="ma-2 text-subtitle-2 text-capitalize" @click="confirmStart = true" :loading="loading" :disabled="loading"><v-icon start>mdi-content-save</v-icon> Speichern & Starten</v-btn>
                        </v-col>
                    </v-row>
                </v-window>
                </v-stepper>
        </v-card>
        <v-dialog v-model="confirmStart" :max-width="mobile? '90%':'33%'">
            <v-card>
                <v-card-title class="text-h5">
                    Umfrage wirklich starten?
                </v-card-title>
                <v-card-text>
                    <p>
                        Sobald eine Umfrage gestartet wurde, können keine weitere Mitglieder hinzugefügt werden!
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green" variant="text" @click="safeSurvey(true,survey.surveyid); confirmStart = false">Starten</v-btn>
                    <v-btn variant="text" @click="confirmStart = false">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script setup>
import BoxComponent from '../../components/BoxComponent.vue'
import EditForm from '../../components/EditForm.vue'
</script>
<script>
export default {
    props: [ 'show', 'survey'],
    data: function() {
        return {
            count: 0,
            groupid: null,
            confirmStart: false,
            mobile: false,
            atLeastOneChipSelected: false,
            showPendingUsers: false,
            pendingUsersText: "Keine ausstehenden Einladungen",
            step: 0,
            loading: false,
            surveyDataValid: false,
            fields: {
                groupid: { text: 'Gruppe', var: 'groupid', value: null, required: true, rules: [ v => !!v || 'erforderlich' ], data: 'groups/GetMyGroups', filter: (g) => g.isAdmin == 1, type: 'select', select: { text: 'name', value: 'id' } },
                name: { text: 'Name', var: 'name', value: null, required: true, rules: [ v => !!v || 'erforderlich' ] },
                description: { text: 'Beschreibung', var: 'description', value: null, required: true, rules: [ v => !!v || 'erforderlich' ] },
                anonymous: { text: 'Anonyme Umfrage', var: 'anonymous', value: true, required: true, type: 'checkbox' }
            },
            questions: [],
            newquestion: {
                valid: false,
                question: null,
                answers: [],
                newanswer: {
                    valid: false,
                    text: null
                }
            },
            groupMembers: [],
            participants: [],
        };
    },
    methods: {
        onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
        },
        closeDialog(){
            this.$emit('update:show',false);
        },
        loadSurveyQuestions(survey){
            this.newquestion.answers = [];
            this.questions = [];
            return this.call('surveys/LoadSurveyQuestions', { surveyid: survey.surveyid }).then(res => {
                    if(res.success){
                        const groupedData = res.data.reduce((acc, item) => {
                            const existingQuestion = acc.find(q => q.question === item.question);
                            
                            if (existingQuestion) {
                                existingQuestion.answers.push(item.answer);
                            } else {
                                acc.push({
                                    question: item.question,
                                    answers: [item.answer]
                                });
                            }

                            return acc;
                        }, []);

                        groupedData.forEach( (obj) => {
                            this.newquestion.question = obj.question;
                            this.newquestion.answers = obj.answers;
                            this.questions.push({
                                question: this.newquestion.question, 
                                answers: this.newquestion.answers
                            });
                            this.newquestion.answers = [];
                        });
                        return true;
                    }else{
                        this.error = res.msg;
                        this.$emit('update:show', false);
                        return false;
                    }
                });
        },
        sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
},
        loadSurveyInformation(survey){
            this.fields.name.value = survey.name;
            this.groupid = survey.groupid;
            this.fields.description.value = survey.description;
            this.fields.anonymous.value = survey.anonym == "Nein" ? false : true;
        },
        addQuestion(){
            this.questions.push({
                question: this.newquestion.question, 
                answers: this.newquestion.answers
            });

            this.newquestion.answers = [];

            this.$refs.newquestion.reset();
            this.$refs.newanswer.reset();
        },
        removeQuestion(index){
            this.questions.splice(index, 1);
        },
        addAnswer(){
            this.newquestion.answers.push(this.newquestion.newanswer.text);
            this.$refs.newanswer.reset();
        },
        removeAnswer(index){
            this.newquestion.answers.splice(index, 1);
        },
        safeSurvey(start,surveyid){
            this.loading = true;

            var postData = {};
            for(var f in this.fields){
                if(!this.fields[f].disabled && this.fields[f].value != null){
                    postData[this.fields[f].var] = this.fields[f].value;
                }
            }

            postData['questions'] = this.questions;
            postData['participants'] = this.participants;

            if(start){
                postData['statusid'] = 2;
            }else{ 
                postData['statusid'] = 1;
            }
            postData['createtime'] = this.survey.createtime;
            postData['startdate'] = this.survey.startdate;
            postData['enddate'] = this.survey.enddate;
            postData['surveyid'] = surveyid;
            
            this.call('surveys/SafeSurveyEdit', postData).then(res => {
                if(res.success){
                    this.$emit('update:show', false);
                    this.step = 0;
                    this.loading = false;
                    for(var f in this.fields){
                        this.fields[f].value = null;
                        if(this.fields[f].type == 'select'){
                            this.fields[f].loadedData = null;
                        }
                    }
                    this.reload = true;
                }
                else {
                    this.error(res.msg);
                }
                this.loading = false;
            });
        },
        remove(array, item) {
            var index = array.indexOf(item);
            if (index >= 0) array.splice(index, 1);
        },
        memberFilter(item, queryText) { 
            return item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.lastname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.firstname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            ;
        },
    },
    computed: {
    },
    watch: {
        'fields.groupid.value'() {
            if(this.count == 0){
                this.fields.groupid.value = this.groupid;
                ++this.count;
            }
            if(this.fields.groupid.value != null){         
                this.loading = true;
                this.call('groups/GetUsersByGroupId',{ id: this.fields.groupid.value }).then(res => {
                    if(res.success && this.fields.groupid.value != 0){
                        if(this.survey.groupid == this.fields.groupid.value){
                            this.call('groups/GetGroupMembersThatPatricipate',{id: this.fields.groupid.value, surveyid: this.survey.surveyid}).then(re =>{
                                this.participants = [];
                                this.groupMembers = [];
                                re.data.forEach(user =>{
                                    this.participants.push(user.id);
                                })
                                this.groupMembers = res.data;
                            })
                        }else{
                            this.groupMembers = res.data;
                            this.participants = res.data.map(gm => gm.id);
                        }
                        this.call('groups/GetMailOfInvitedUsers',{ id: this.fields.groupid.value }).then(res2 => {
                            if(res2.data[0] != null){
                                this.showPendingUsers = true;
                                this.pendingUsersText = "Ausstehende Einladungen müssen nachträglich hinzugefügt werden:<br>";
                                res2.data.forEach(user => {
                                    this.pendingUsersText += user.email+", "
                                })
                                this.pendingUsersText = this.pendingUsersText.substring(0, this.pendingUsersText.length - 2)+"<br><br>";
                                
                            }else{
                                this.showPendingUsers = false;
                            }
                        });
                    }
                    else{ 
                        this.participants = [];
                        this.groupMembers = [];
                    }
                    this.loading = false;
                });
            }else{
                this.showPendingUsers = false;
            }
        },
        participants: {
            handler() {
                this.atLeastOneChipSelected = this.participants.length > 0;
            },
            immediate: true,
            deep: true,
        },
        show: {
            immediate: true,
            handler(newShowStatus) {
                if (newShowStatus) {
                // Die Komponente wird sichtbar
                this.loadSurveyQuestions(this.survey);
                this.loadSurveyInformation(this.survey);
                }
            }
        }
    },
    mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
}
</script>
<style>
.v-stepper--alt-labels .v-stepper-item{
    flex-basis: 0px;
}
</style>