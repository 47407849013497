<template>
    <v-container v-show="!hideEmpty || (hideEmpty && data != null && data.length > 0)" fluid :style="mobile ? {'padding':'unset'} : {}">
        <v-row v-if="title != null">
            <v-col cols="5">
                <span class="text-h6">{{title}}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn v-if="createfields != null" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="showCreate = true"><v-icon start>mdi-plus</v-icon> Neu</v-btn>
                <slot name="buttonbar" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-title v-show="showFilter && data != null">
                        <v-text-field :disabled="loading"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Filter"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                
                    <v-data-table 
                        :loading="loading" 
                        :headers="headers" 
                        :items="data" 
                        :items-per-page="10" 
                        :search="search" 
                        class="elevation-1" 
                        :group-by="group">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td v-for="key in headers" :key="key.value" :class="key.align == null || key.align == 'start' ? 'text-start' : 'text-end'">
                                    <span :style="mobile && key.value == 'description' ? {'display': 'inline-block','inline-size': '200px', 'overflow-wrap': 'break-word'}:{}" v-if="key.type == null || key.type == 'text'">{{ typeof key.value == 'function' ? key.value(item) : item[key.value]}}</span>
                                    <v-checkbox-btn v-if="key.type == 'checkbox'" v-model="item[key.value]" :value="item[key.value]" disabled></v-checkbox-btn>
                                    <span v-if="key.type == 'datetime'">{{new Date(item[key.value] * 1000).toLocaleString('de-DE').slice(0, -3)}}</span>

                                    <v-tooltip v-if="key.value == 'actions' && editfields != null" location="bottom">
                                        <template v-slot:activator="{ props }">
                                            <v-btn color="primary" size="small" fab @click="editItem(item)" v-bind="props"><v-icon>mdi-pencil</v-icon></v-btn> 
                                        </template>
                                        <span>bearbeiten</span>
                                    </v-tooltip>
                                    <!-- custom actions -->
                                    <slot v-if="key.value == 'actions'" v-bind="item" />


                                    <v-tooltip v-if="key.value == 'actions' && deletefield != null && (deleteif == null || deleteif(item))" location="bottom">
                                        <template v-slot:activator="{ props }">
                                            <v-btn color="error" size="small" fab @click="deleteItem(item)" v-bind="props"><v-icon>mdi-delete</v-icon></v-btn> 
                                        </template>
                                        <span>löschen</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="showCreate" max-width="600px">
            <v-card tile>
                <v-card-title>
                    <span>{{title}} anlegen</span>
                    <v-spacer></v-spacer>
                    <v-btn :size="mobile ? '30px': 'default'" icon @click="showCreate = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container>
                    <EditForm :editonly="showCreate" :value="newItem" :fields="createfields" :api_write="api_create" />
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-if="item != null" v-model="showEdit" max-width="600px">
            <v-card tile>
                <v-card-title>
                    <span>{{title}}{{item.name != null ? " '"+item.name+"'" : ''}} bearbeiten</span>
                    <v-spacer></v-spacer>
                    <v-btn :size="mobile ? '30px': 'default'" icon @click="showEdit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container>
                    <EditForm :editonly="showEdit" v-model="item" :fields="editfields" :api_write="api_edit" />
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirm" max-width="22%">
            <v-card>
                <v-card-title class="text-h5">
                    {{title}} wirklich löschen?
                </v-card-title>
                <!--v-card-text>
                    
                </v-card-text-->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="confirm = false">Nein</v-btn>
                    <v-btn color="red" variant="text" @click="confirmFunc()">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script setup>
import EditForm from '../components/EditForm.vue'
</script>
<script>
    export default {
        props: [ 'hasData', 'hidewithnodata', 'nofilter', 'reload', 'loadeddata', 'value', 'title', 'headers', 'api_load', 'createfields', 'api_create', 'editfields', 'api_edit', 'deletefield', 'api_delete', 'deleteif', 'group', 'sort' ],
        data: function() {
            return {
                loading: false,
                showEdit: false,
                showCreate: false,
                item: null,
                newItem: null,
                data: [],
                search: null,
                confirm: false,
                mobile: false,
                confirmFunc: () => {},
            }
        },
        computed: {
            showFilter() {
                return this["nofilter"] == null;
            },
            hideEmpty() {
                return this["hidewithnodata"] != null;
            }
        },
        methods: {
            editItem(item) {
                this.item = item;
                this.showEdit = true;
            },
            deleteItem(item) {
                this.confirmFunc = () => {
                    if(this.api_delete) {
                        this.call(this.api_delete + '?' + this.deletefield + '=' + item[this.deletefield]).then(res => {
                            if(res.success){
                                this.loadData();
                            }
                            else {
                                this.error(res.msg);
                            }
                        });
                    }
                    else {
                        var i = this.data.findIndex((e) => { e[this.deletefield] == item[this.deletefield] });
                        this.data.splice(i, 1);
                        if(this.value != null){
                            this.$emit('value', this.data);
                        }
                        this.loadData();
                    }
                    this.confirm = false;
                }
                this.confirm = true;
            },
            loadData(){
                if(this.api_load){
                    this.loading = true;
                    this.call(this.api_load).then(res => {
                        if(res.success){
                            if(typeof res.data == 'object'){
                                this.data = Object.values(res.data);
                                if(this.api_load == "groups/getmygroups"){
                                    for(let $x = 0;$x<this.data.length;$x++){
                                        this.data[$x].isAdmin = this.data[$x].isAdmin === 1 ? 'Ja' : 'Nein';
                                    }
                                }
                            }
                            else this.data = res.data;
                            this.$emit('update:loadeddata', this.data);
                        }
                        else {
                            this.error(res.msg);
                        }
                        this.loading = false;
                    });
                }
                else if(this.value != null){
                    this.data = this.value;
                }
            },
            onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
            },
        },
        watch: {
            value(){
                if(this.value != null){
                    console.log("value changed");
                    this.loadData();
                }
                else this.data = [];
            },
            showEdit() {
                if(!this.showEdit){
                    this.loadData();
                }
            },
            item() {
                if(this.item != null) {
                    console.log("item edit changed");
                }
            },
            data() {
               this.$emit('update:hasData', this.data != null);
            },
            newItem(){
                if(this.newItem != null){
                    console.log("new item added");
                    this.data.push(this.newItem);
                    this.newItem = null;
                    this.loadData();
                }
            },
            api_load(){
                console.log("api load changed");
                this.loadData();
            },
            reload(){
                if(this.reload){
                    console.log("reload triggered");
                    this.loadData();
                    this.$emit('update:reload', false);
                    this.$emit('reload');
                }
            }
        },
        mounted () {
            this.loadData();
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
    }
</script>

<style scoped>

</style>