import {createRouter, createWebHistory} from 'vue-router'
import Login from '@/views/LoginView.vue'
import Register from '@/views/RegisterView.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import NewPassword from '@/views/NewPassword.vue'
import Home from '@/views/HomeView.vue'
import Teams from '@/views/TeamsView.vue'
import Surveys from '@/views/SurveysView.vue'
import Results from '@/views/ResultsView.vue'
import Settings from '@/views/SettingsView.vue'
import SettingsUser from '@/views/settings/UserView.vue'
import SettingsPassword from '@/views/settings/PasswordView.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/newpassword/:token',
    name: 'NewPassword',
    props: true,
    component: NewPassword
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/teams',
    name: 'Teams',
    component: Teams
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: Surveys
  },
  {
    path: '/results',
    name: 'Results',
    component: Results
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    redirect: '/settings/user',
    children: [
      {
        path: 'user',
        name: 'Settings-User',
        component: SettingsUser,
      },
      {
        path: 'password',
        name: 'Settings-Password',
        component: SettingsPassword,
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log("before route");
  if(!window.$store){
    next();
  }
  else if (!window.$store.loggedIn && to.name != 'Login' && to.name != 'Register' && to.name != 'ForgotPassword' && to.name != 'NewPassword') { //redirect to login
    window.$store.menu = false;
    next({ name: 'Login', query: { return: to.path } });
  }
  else if(window.$store.loggedIn && (to.name == 'Login' || to.name == 'Register' || to.name == 'ForgotPassword' || to.name == 'NewPassword')) { //redirect to home
    next({ name: 'Home' });
    window.$store.menu = true;
  }
  else next();
});

export default router
