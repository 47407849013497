<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12" lg="8" xl="6">
                <v-card elevation="2" class="pa-4" variant="outlined" tile>
                    <v-card elevation="2" class="pa-4 mb-3 text-h6" color="blue-darken-1" dark>Passwort vergessen</v-card>
                    <v-container>
                        <v-form ref="registerform" v-model="valid" v-on:submit.prevent="reset">
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="email" v-model="email" :rules="emailRules" label="E-Mail Adresse" required :disabled="attempt">
                                        <template v-slot:append>
                                            <v-icon>mdi-email-outline</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            
                            <v-row v-show="error != null" justify="center" dense>
                                <v-col md="7">
                                    <v-alert density="compact" type="error">
                                        Error: <strong>{{error}}</strong>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense v-if="isUniMail">
                                <v-col md="7">
                                    <span>
                                        Es scheint sich um eine Email der Universität Trier zu handeln. Bitte wenden Sie sich an das ZIMK - Zentrum für Informations-, Medien- und Kommunikationstechnologie der Universität Trier, falls Sie Ihr Passwort vergessen haben.
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col md="4">
                                    <v-btn type="submit" color="primary" block class="ma-2 text-subtitle-2 text-capitalize" :disabled="!valid" :loading="attempt" @click.prevent="reset()">E-Mail anfordern</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row justify="center" dense>
                            <v-col md="4" class="px-4">
                                <v-btn variant="text" @click="$router.push('/login')" block>Zurück zum Login</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                emailRules: [
                    v => !!v || 'E-Mail erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail nicht gültig',
                    v => (v && v.length <= 50) || 'Email darf maximal 50 Zeichen lang sein',
                ],
                error: null,
                attempt: false,
                valid: false,
                email: null,
                isUniMail: false,
            }
        },
        methods: {
            reset: function() {
                this.attempt = true;
                const domain = this.email.split("@")[1];

                if(domain === 'uni-trier.de'){
                    this.isUniMail = true;
                    this.attempt = false;
                }else{
                    this.isUniMail = false;
                    this.call('users/RequestPasswordReset', {
                        email: this.email,
                    }).then(res => {
                        this.attempt = false;
                        if(res.success){
                            this.$router.push('/login');
                        }
                        else {
                            this.error = res.msg;
                        }
                    })
                }
            }
        },
        created () {

        }
    }
</script>

<style scoped>

</style>