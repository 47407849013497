<template>
  <v-app>
    <v-navigation-drawer v-if="$store.initialized && $store.loggedIn" v-model="menu" style="border-color:black">
      <v-list class="pt-4 v-theme--light bg-primary">
        <v-list-item id="avatar" class="rounded-lg">
          <v-img cover :src="$store.avatar" height="80px" width="80px" />
        </v-list-item>
        
        <p class="text-center mt-1">{{ $store.userData?.title != null ? `${$store.userData.title} ` : '' }}{{$store.userData?.firstname}} {{$store.userData?.lastname}}</p>
        </v-list>

      <v-divider></v-divider>
      

      <v-list density="compact" class="mt-4">
        <v-list-item link v-for="(item, i) in menuItems" :key="i" :to="item.link">
          <template v-slot:prepend>
            <v-icon >{{"mdi-"+item.icon}}</v-icon>
          </template>
          <v-list-item-title >{{item.name}}</v-list-item-title>

        </v-list-item>

        <v-divider class="mt-5"></v-divider>
        <v-list-item link to="/settings">
          <template v-slot:prepend>
            <v-icon>mdi-cog</v-icon>
          </template>
            <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout()">
          <template v-slot:prepend>
            <v-icon>mdi-exit-to-app</v-icon>
          </template>
            <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list density="compact">
          <v-list-item link v-for="(item, i) in bottomMenuItems" :key="i" v-bind="item.link">
              <v-icon>{{"mdi-"+item.icon}}</v-icon>
              <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item>
        
          <!-- { name: 'Datenschutz', icon: 'fingerprint', link: { href: '#' } }, -->
          <!--<v-list-item link @click="viewPdf('Datenschutzerklärung', 'pdfs/Datenschutzerklaerung.pdf')">
            <v-list-item-icon>
              <v-icon>mdi-fingerprint</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Datenschutz</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->

          <!--   { name: 'Logout', icon: 'exit_to_app', link: { to: '/logout' } },  -->
              
          <!--{ name: 'Datenschutz', icon: 'book', link: { href: '#' } },-->
          <v-list-item link href="pdfs/TeamPollHandbook.pdf" target="_blank">
            <template v-slot:prepend>
              <v-icon>mdi-book-outline</v-icon>
            </template>
              <v-list-item-title>Anleitung</v-list-item-title>
          </v-list-item>


        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark >
      <v-app-bar-nav-icon v-if="$store.initialized && $store.loggedIn" @click="menu = !menu"></v-app-bar-nav-icon>
      <v-toolbar-title>TeamPoll</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu tile location="left" offset-y v-model="errorMenu" v-if="$store.initialized && $store.loggedIn">
        <template v-slot:activator="{ props }" >
          <v-btn text icon v-bind="props" class="mr-3" :hidden="true">
            <v-icon>mdi-bell</v-icon>
            <v-badge v-show="$store.messageCount > 0" color="red" :content="$store.messageCount" bottom />
          </v-btn>
        </template>
        <v-list density="compact" min-width="220px">
          <v-list-item v-for="(item, i) in $store.messages" :key="i" >
              <v-icon :color="item.error ? 'mdi-bell':'mdi-alert'">{{item.error ? 'mdi-bell':'mdi-alert'}}</v-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            <v-badge v-show="item.count > 1" color="grey" :content="item.count" offset-x="18" offset-y="10" location="bottom" />
          </v-list-item>
          <v-list-item v-show="$store.messages.length == 0" >
              <v-list-item-subtitle>Keine Einträge.</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-menu>

      

    </v-app-bar>

    
    <v-main id="content" >
      <v-container v-if="!$store.initialized" fluid>
        <v-row justify="center" class="mt-5" >
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-row>
      </v-container>
      <router-view v-if="$store.initialized"></router-view>
    </v-main>
    <v-snackbar location="top" app color="rgba(0,0,0,0)" class="elevation-0" tile v-model="$store.error.show" :timeout="5000" >
            <v-alert density="compact" type="error">
                <strong v-show="$store.error.source != null" class="text-uppercase">{{$store.error.source}}:</strong> {{$store.error.msg}}
            </v-alert>
    </v-snackbar>

    <v-dialog v-model="$pdf.view" persistent max-width="80%" :fullscreen="$vuetify.display.mdAndDown" >
        <v-card tile height="90vh">
            <v-card-title>
              <span>{{$pdf.title}}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="$pdf.view = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <embed v-if="$pdf.view" :src="$pdf.file" width="100%" class="pdfview" />
        </v-card>
    </v-dialog>

    <!-- SUCCESS MSG -->
    <v-snackbar v-model="$store.success" color="success" :timeout="5000">
      <span class="text-center text-body-1">{{$store.successMsg}}</span>
    </v-snackbar>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {

  },

  computed: {
  },
  data: () => ({
    menu: true,
    renderComponent: true,
    menuItems: [
      { name: 'Startseite', icon: 'home', link: '/home', props:{prependIcon:'mdi-home'}},
      { name: 'Gruppen', icon: 'folder', link: '/teams' },
      { name: 'Umfragen', icon: 'clipboard-list', link: '/surveys' },
      { name: 'Ergebnisse', icon: 'chart-box', link: '/results' },
    ],
    bottomMenuItems: [

    ],
    errorMenu: false,
  }),
  methods: {
    async forceRender() {
   // Remove MyComponent from the DOM
   this.renderComponent = false;

   // Then, wait for the change to get flushed to the DOM
      await this.$nextTick();
    },
    logout: function() {
        this.call('users/logout').then(res => {
            console.log("attempted logout");
            console.log(res);
            
            if(res.success){
                this.$store.loggedIn = false;
                this.$router.push('/login');
            }
            else {
                this.error = res.msg;
            }
        })
    },
  },
  watch: {
    '$store.initialized'() {
      if(this.$store.initialized) {
        
        if(this.$store.loggedIn && (this.$route.name == 'Login' || this.$route.name == 'Register' || this.$route.name == 'ForgotPassword' || this.$route.name == 'NewPassword')){
          if(this.$route.query.return){
              this.$router.push(this.$route.query.return);
          }
          else this.$router.push('/home');
        }
        else if(!this.$store.loggedIn && this.$route.name != 'Login' && this.$route.name != 'Register' && this.$route.name != 'ForgotPassword' && this.$route.name != 'NewPassword'){
          this.$router.push({ name: 'Login', query: { return: this.$route.path } });
        }
      }
    },
    errorMenu(){
      if(this.errorMenu){
        this.$store.messageCount = 0;
      }
    } 
  },
  created () {
  },
  mounted () {
  }
};
</script>

<style>
:root {
  --color-main: #5d9a60;
  --color-main-light: #aeccaf;

  --font-default: 16px;
  --font-small: 14px;
  --font-big: 18px;
  --font-head: 20px;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;

  font-family: Roboto,"Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-default);
}

.pdfview {
  height: calc(100% - 70px);
}

</style>


<style scoped>
#avatar {
  background-color: #fff;
  width: 80px;
  height: 80px;
  padding: 6px;
  margin: 0px auto;
}

#content {
  background-color: #f5f5f5;
}

#logo {
  max-width: 100px;
  height: 40px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Material-Icons.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings:'liga';
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

</style>
