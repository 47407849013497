import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import pdfview from '@/plugins/pdfview';
import sasp from '@/plugins/sasp';
import router from './router'


// Custom Components
import BoxComponent from '@/components/BoxComponent.vue'
import HelpBox from '@/components/HelpBox.vue'
import EditForm from '@/components/EditForm.vue'
import EditTable from '@/components/EditTable.vue'

// Custom Dialogs
import SurveyCreateDialog from '@/views/dialogs/SurveyCreateDialog.vue'
import SurveyAnswerDialog from '@/views/dialogs/SurveyAnswerDialog.vue'
import SurveyResultDialog from '@/views/dialogs/SurveyResultDialog.vue'
import SurveyEditDialog from '@/views/dialogs/SurveyEditDialog.vue'

import InvitesTable from '@/views/tables/InvitesTable.vue'

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(pdfview)
  .use(sasp)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('BoxComponent', BoxComponent)
  .component('HelpBox', HelpBox)
  .component('EditForm', EditForm)
  .component('EditTable', EditTable)
  .component('SurveyCreateDialog', SurveyCreateDialog)
  .component('SurveyAnswerDialog', SurveyAnswerDialog)
  .component('SurveyResultDialog', SurveyResultDialog)
  .component('SurveyEditDialog', SurveyEditDialog)
  .component('InvitesTable', InvitesTable)
  .mount('#app')

library.add(fas)