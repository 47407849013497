<template>
    <v-row justify="center">
        <v-col md="12" lg="12" xl="12">
            <v-card v-if="mobile" elevation="2" :style="{'background-color':'#f5f5f5'}" tile>
                <v-card v-if="title != null" elevation="2" :style="{'margin':'5px'}" class="pa-4 mb-3 text-h6" color="blue-darken-1" dark>
                    {{title}}
                </v-card>
                <v-container fluid :style="{'background-color':'#f5f5f5', 'padding':'5px'}">
                    <slot />
                </v-container>
            </v-card>
            <v-card v-if="!mobile" elevation="2" class="pa-4" variant="outlined" tile>
                <v-card v-if="title != null" elevation="2" class="pa-4 mb-3 text-h6" color="blue-darken-1" dark>
                    {{title}}
                </v-card>
                <v-container fluid>
                    <slot />
                </v-container>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'BoxComponent',
        props: [ 'title' ],
        data: function() {
            return {
                mobile: false,
            }
        },
        mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
        methods: {
            onResize() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            },
        }
    }
</script>

<style scoped>

</style>