<template>
  <v-dialog persistent v-if="survey !== null && show && surveyQuestions !== null" :model-value="show" max-width="80%" @update:value="$emit('update:show', $event)">
      <v-card tile>
        <v-card-title>
          <v-row>
            <v-col cols="6" style="text-align:left">
              <span>Umfrage: {{survey.name}}</span>
            </v-col>
            <v-col cols="6" style="text-align:right">
              <v-btn :size="mobile ? '30px': 'default'" icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
        </v-row>
        </v-card-title>
        <v-stepper :alt-labels="mobile" v-model="step" non-linear show-actions style="overflow-y:auto">
          <v-stepper-header>
              <v-stepper-item value="1" color="blue-darken-2">Allgemeines</v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item value="2" color="blue-darken-2" :complete="answered">Fragen</v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item value="3" color="blue-darken-2" :editable="answered">Fertigstellen</v-stepper-item>
          </v-stepper-header>
          <v-window>
          <v-stepper-window>
            <v-stepper-window-item value="1">
                <v-row>
                  <BoxComponent title="Allgemeine Informationen">
                        <v-text-field :model-value="survey.name" label="Beratungspunkt" readonly></v-text-field>
                        <v-text-field :model-value="survey.description" label="Art der Entscheidung" readonly></v-text-field>
                        <v-text-field :model-value="survey.groupname" label="Gruppe" readonly></v-text-field>
                        <v-text-field :model-value="survey.creator.email" label="Ersteller" readonly></v-text-field>
                        <v-text-field :model-value="surveyQuestionsIds.length" label="Anzahl der Fragen" readonly></v-text-field>
                        <v-text-field :model-value="survey.startdate" label="Umfragestart" readonly></v-text-field>
                  </BoxComponent>
                </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item value="2">
               <v-row v-for="(item) in surveyQuestions" :key="item.id" dense>
                <v-col>
                  <v-container fluid class="py-0" >
                    <v-row dense>
                      <v-col class="font-weight-bold bg-blue-darken-1 text-white elevation-1">
                        {{ item.text }}
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="auto" class="ma-0">   
                        <v-radio-group v-model="item.value" @update:model-value="answerChange(item)" class="ma-0" >   
                          <v-radio v-for="answer in item.answers" :key="answer.id" :label="answer.text" :value="answer.id"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
               </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item value="3">
                <v-row>
                  <v-col>
                    <span>Nachdem Sie auf <b>'Abschicken'</b> geklickt haben, werden Ihre Antworten dauerhaft gespeichert und können nicht mehr geändert werden.</span>
                  </v-col>
                </v-row>
            </v-stepper-window-item>
          </v-stepper-window>
          <v-row>
            <v-col :cols="step==2?'4':'6'" style="text-align:left;align-content: end">
                <v-btn variant="text" @click="step = 0" v-if="step == 0"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                <v-btn variant="text" @click="step = 0" v-if="step == 1"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                <v-btn variant="text" @click="step = 1" v-if="step == 2"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
            </v-col>
            <v-col :cols="step==2?'8':'6'" style="text-align:right">
              <v-btn variant="text" v-if="step == 0" @click="step = 1" location="right">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
              <v-btn variant="text" v-if="step == 1" @click="step = 2" :disabled="!answered">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
              <v-btn color="primary" v-if="step == 2" dark class="ma-2 text-subtitle-2 text-capitalize" @click="endSurvey()"><v-icon start>mdi-check</v-icon> Abschicken</v-btn>
            </v-col>
        </v-row>
      </v-window>
        </v-stepper>
      </v-card>
    </v-dialog>
</template>
<script setup>
import BoxComponent from '../../components/BoxComponent.vue'
</script>
<script>
export default {
  props: [ 'show', 'survey' ],
  data: function() {
    return {
      surveyQuestions: null,
      surveyQuestionsIds: null,
      step: 1,
      mobile: false,
    };
  },
  methods: {
    closeDialog(){
            this.$emit('update:show',false);
        },
    async startSurvey(){

      this.step = 0;
      this.surveyQuestions = [];
      this.surveyQuestionsIds = [];
      var givenAnswers = [];

      if(this.survey.IsStartable4User){
        console.log("Survey not startet... starting...");
        var startRes = await this.call("surveys/StartSurvey", { surveyid: this.survey.surveyid });
        if(!startRes.success){
          return;
        }
      }
      else {
        console.log("Survey arleardy startet...");
        var answerRes = await this.call("surveys/ListUserAnswers/", { surveyid: this.survey.surveyid });
        givenAnswers = answerRes.data;
      }

      this.call("surveys/ListSurveyQuestions/", { surveyid: this.survey.surveyid, asUser: true }).then(res => {
        for (const [key, q] of Object.entries(res.data)) {
          q.value = givenAnswers[q.id] != null ? givenAnswers[q.id].value : null;
          this.surveyQuestionsIds.push(key);
        }
        this.surveyQuestions = res.data;
        console.log("Open survey dialog");
        this.$forceUpdate();
      })
    },
    answerGivenCount(category){
      return category.questions.filter(q => q.value != null).length;
    },
    answerChange(item){

      //call SetUserAnswer
      this.call("surveys/SetUserAnswer", { surveyid: this.survey.surveyid, qid: item.id, value: item.value }).then(res => {
        console.log(res)
      });
      
      this.$forceUpdate();
    },
    endSurvey(){
      this.call("surveys/EndSurvey", { surveyid: this.survey.surveyid }).then(res => {
        if(res.success){
          this.$emit('update:show', false);
        }
      });
    },
    onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
        },
  },
  computed: {
    answered(){
      return this.surveyQuestions != null ? Object.entries(this.surveyQuestions).filter(q => q[1].value == null).length == 0 : false;    }
  },
  watch: {
    show(){
        if(this.show && this.survey != null){
            this.startSurvey();
        }
    }
  },
  mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
}
</script>
<style>
.v-stepper--alt-labels .v-stepper-item{
    flex-basis: 0px;
}
</style>