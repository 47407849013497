// plugins/sasp.js
import { reactive } from 'vue';

export default {
  install: (app) => {
    const $pdf = reactive({
      view: false,
      title: null,
      file: null,
    });

    Object.defineProperty(app.config.globalProperties, '$pdf', {
      get() {
        return $pdf;
      },
      set(value) {
        $pdf.view = value.view;
        $pdf.title = value.title;
        $pdf.file = value.file;
      },
    });

    window.viewPdf = app.config.globalProperties.viewPdf = (title, file) => {
      $pdf.title = title;
      $pdf.file = file;
      $pdf.view = true;
    };
  },
};