<template>
    <EditTable @reload="$emit('reload')" v-model:reload="reloadInvites" v-model:hasData="hasData" :reload="reloadInvites" :hasData="hasData" title="Offene Team Einladungen" :headers="cols_invites" api_load="groups/ListMyInvites" nofilter hidewithnodata>
        <template #default="item">
            <v-tooltip v-if="item.declined == 0" location="bottom">
                <template v-slot:activator="{ props }">
                    <v-btn color="primary" size="small" fab @click="acceptInvite(item)" v-bind="props"><v-icon>mdi-check</v-icon></v-btn> 
                </template>
                <span>annehmen</span>
            </v-tooltip>
            <v-tooltip v-if="item.declined == 0" location="bottom">
                <template v-slot:activator="{ props }">
                    <v-btn color="red" size="small" fab @click="declineInvite(item)" v-bind="props"><v-icon>mdi-cancel</v-icon></v-btn> 
                </template>
                <span>ablehnen</span>
            </v-tooltip>
        </template>
    </EditTable>
</template>
<script setup>
import EditTable from '../../components/EditTable.vue'
</script>

<script>
    export default {
        props: [ 'value' ],
        data: function(){
            return {
                hasData: false,
                reloadInvites: false,
                cols_invites: [
                    { title: 'Gruppe', align: 'start', value: item => item.groupdata.name },
                    { title: 'Eingeladen von', value: item => item.groupdata.creator.email },
                    { title: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
            }
        },
        methods: {
            acceptInvite(item){
                this.call('groups/AcceptInvite', { inviteid: item.id }).then(() => {
                    this.reloadInvites = true;
                });
            },
            declineInvite(item){
                this.call('groups/DeclineInvite', { inviteid: item.id }).then(() => {
                    this.reloadInvites = true;
                });
            }
        },
        watch: {
            hasData(){
                this.$emit('input', this.hasData);
            }
        }
    }
</script>
