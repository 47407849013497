<template>
    <v-container fluid :style="mobile ? {'padding':'unset'} : {}">
        <BoxComponent title="Einstellungen" >
            <v-tabs>
                <v-tab to="/settings/user"><v-icon start>mdi-card-account-mail</v-icon> Persönliche Daten</v-tab>
                <v-tab v-if="!$store.userData.isLdapUser" to="/settings/password"><v-icon start>mdi-key</v-icon> Passwort</v-tab>
            </v-tabs>
            <v-divider />
            <router-view></router-view>
        </BoxComponent>
    </v-container>
</template>
<script setup>
import BoxComponent from '../components/BoxComponent.vue'
</script>
<script>
    export default {
        name: 'SettingsView',
        data: function() {
            return {
                mobile: false,
            }
        },
        methods: {
            onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
            },
        },
        mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
    }
</script>