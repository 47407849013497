<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12" lg="8" xl="6">
                <v-card elevation="2" class="pa-4" variant="outlined" tile>
                    <v-card elevation="2" class="pa-4 mb-3 text-h6" color="blue-darken-1" dark>Registration</v-card>
                    <v-container>
                        <v-form ref="registerform" v-model="registerformValid" v-on:submit.prevent="register">
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="reg_email" v-model="reg_email" :rules="emailRules" label="E-Mail Adresse" required :disabled="attemptRegister">
                                        <template v-slot:append>
                                            <v-icon>mdi-email-outline</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="firstname" v-model="firstname" :rules="nameRules" label="Vorname" required :disabled="attemptRegister">
                                        <template v-slot:append>
                                            <v-icon>mdi-badge-account-horizontal</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="lastname" v-model="lastname" :rules="nameRules" label="Nachname" required :disabled="attemptRegister">
                                        <template v-slot:append>
                                            <v-icon>mdi-badge-account-horizontal</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-select id="gender" :rules="genderRules" :disabled="attemptRegister"
                                        v-model="gender"
                                        :items="genderOptions"
                                        label="Anrede"
                                        outlined
                                        dense
                                        required
                                    > 
                                    <template v-slot:append>
                                        <v-icon>mdi-badge-account-horizontal</v-icon>
                                    </template>
                                </v-select>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="token" v-model="token" label="Einmalcode" required :disabled="attemptRegister">
                                        <template v-slot:append>
                                            <v-icon>mdi-key</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-show="error != null" justify="center" dense>
                                <v-col md="7">
                                    <v-alert density="compact" type="error">
                                        Error: <strong>{{error}}</strong>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            
                            <v-row justify="center">
                                <v-col md="4">
                                    <v-btn type="submit" color="primary" block class="ma-2 text-subtitle-2 text-capitalize" :disabled="!registerformValid" :loading="attemptRegister" @click.prevent="register()">Registrieren</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row justify="center" dense>
                            <v-col md="4" class="px-4">
                                <v-btn variant="text" @click="$router.push('/login')" block>Zurück zum Login</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                emailRules: [
                    v => !!v || 'E-Mail erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail nicht gültig',
                    v => (v && v.length <= 50) || 'Email darf maximal 50 Zeichen lang sein',
                ],
                nameRules: [
                    v => !!v || 'erforderlich',
                ],
                genderRules: [
                    v => !!v || 'erforderlich',
                    v => v!=null || 'erforderlich',
                ],
                error: null,
                attemptRegister: false,
                registerformValid: false,
                reg_email: null,
                firstname: null,
                lastname: null,
                gender: null,
                genderOptions: [
                    { title: 'Anrede auswählen', value: null},
                    { title: 'Sehr geehrter Herr [Titel] [Nachname]', value: 'm' },
                    { title: 'Sehr geehrte Frau [Titel] [Nachname]', value: 'w' },
                    { title: 'Guten Tag [Vorname] [Nachname]', value: 'd' }
                ],
                token: null
            }
        },
        methods: {
            register: function() {
                this.attemptRegister = true;
                this.call('users/CreateUser', {
                    email: this.reg_email,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    gender: this.gender,
                    token: this.token
                }).then(res => {
                    this.attemptRegister = false;
                    console.log("attempted register");
                    
                    if(res.success){
                        const registration = 'success';
                        this.$router.push({ path: '/login', query: { registration } });
                    }
                    else {
                        this.error = res.msg;
                    }
                })
            }
        },
        created () {

        }
    }
</script>

<style scoped>

</style>