<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12" lg="8" xl="6">
                <v-card elevation="2" class="pa-4" variant="outlined" tile>
                    <v-card elevation="2" class="pa-4 mb-3 text-h6" color="blue-darken-1" dark>Login</v-card>
                    <v-container>
                        <v-form ref="loginform" v-model="loginformValid" v-on:submit.prevent="login">
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="email" v-model="email" :rules="emailRules" label="E-Mail Adresse" required :disabled="attemptLogin">
                                        <template v-slot:append>
                                            <v-icon>mdi-email-outline</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="password" type="password" v-model="password" :rules="passwordRules" label="Passwort" required :disabled="attemptLogin">
                                        <template v-slot:append>
                                            <v-icon>mdi-lock</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-show="error != null" justify="center" dense>
                                <v-col md="7">
                                    <v-alert density="compact" type="error">
                                        Error: <strong>{{error}}</strong>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="showRegisterMessage">
                                <v-col md="3" style="background-color: #4caf50; color: #fff; border-radius: 5px;" class="text-capitalize text-center">
                                    Registrierung erfolgreich!
                                    <br />
                                    Email versendet
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col md="4">
                                    <v-btn type="submit" color="primary" block class="text-subtitle-2 text-capitalize" :disabled="!loginformValid" :loading="attemptLogin" @click.prevent="login()">Login</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row justify="center" dense>
                            <v-col md="4" class="px-4">
                                <v-btn variant="text" @click="$router.push('/register')" block>Registrieren</v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center" dense>
                            <v-col md="4" class="mt-4 text-center">
                                <a @click="$router.push('/forgotpassword')">Passwort vergessen?</a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                showRegisterMessage: false,
                attemptLogin: false,
                email: null,
                password: null,
                loginformValid: false,
                emailRules: [
                    v => !!v || 'E-Mail erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail nicht gültig',
                    v => (v && v.length <= 50) || 'Email darf maximal 50 Zeichen lang sein',
                ],
                passwordRules: [
                    v => !!v || 'Passwort erforderlich',
                ],
                error: null,
            }
        },
        methods: {
            login: function() {
                this.attemptLogin = true;
                this.call('users/login', {
                    email: this.email,
                    password: this.password.trim()
                }).then(res => {
                    this.attemptLogin = false;
                    console.log("attempted login");
                    
                    if(res.success){
                        this.status().then(res => {
                            if(res.success){
                                window.$store.loggedIn = true;
                                this.redirect();
                            }
                        });
                    }
                    else {
                        this.error = res.msg;
                    }
                })
            },
            redirect: function() {
                if(this.$route.query.return){
                    this.$router.push(this.$route.query.return).catch(() => {});
                }
                else this.$router.push('/home').catch(() => {});
            }
        },
        created () {
            const registration = this.$route.query.registration;

            if (registration == 'success') {
                this.showRegisterMessage = true;
            }
        }
    }
</script>

<style scoped>

</style>