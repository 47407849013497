<template>
    <v-container fluid :style="mobile ? {'padding':'unset'} : {}">
        <BoxComponent title="Ergebnisse">
            <p>
                Hier sehen Sie Ihre Ergebnisse aus den Umfragen.
            </p>
            <p>
                Sobald die Umfrage beendet wurde, können hier alle Ergebnisse von allen Gruppenmitgliedern eingesehen werden.
            </p>
            <EditTable v-model:loadeddata="groups" v-model:reload="reload" :loadeddata="groups" :reload="reload" title="Umfragen" :headers="cols" api_load="surveys/ListMySurveys/3" >
                <template v-slot:default="item">
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-btn size="small" fab @click="startSurvey(item)" v-bind="props" color="success"><v-icon>mdi-eye</v-icon></v-btn> 
                        </template>
                        <span>Ergebnisse anzeigen</span>
                    </v-tooltip>
                </template>
            </EditTable>
            <SurveyResultDialog v-model:show="showSurveyResultDialog" :show="showSurveyResultDialog" :survey="activeSurvey"  @update:show="handleSurveyResultsDialogUpdate"/>
          
        </BoxComponent>
    </v-container>
</template>
<script setup>
import BoxComponent from '../components/BoxComponent.vue'
import EditTable from '../components/EditTable.vue'
import SurveyResultDialog from './dialogs/SurveyResultDialog.vue'
</script>
<script>
    export default {
        name: 'ResultsView',
        data: () => {
            return {
                showSurveyResultDialog: false,
                activeSurvey: null,
                reload: false,
                mobile: false,
                cols: [
                    { title: 'Aktionen', align: 'start', sortable: false, value: 'actions' },
                    { title: 'Umfrage', value: 'name' },
                    { title: 'Gruppe', value: 'groupname' },
                    { title: 'Ist Anonym?', value: 'anonym' }
                ],
                groups: null,
                group: null,
            }
        },   
        methods: {
            handleSurveyResultsDialogUpdate(newValue) {
                this.showSurveyResultDialog = newValue;
            },
            startSurvey(item){
                this.activeSurvey = item,
                this.showSurveyResultDialog = true;
            },
            jumpToProgress(groupid){
                this.group = groupid;
                window.location.hash = '';
                window.location.hash = 'progress';
            },
            onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
            },
        },
        watch: {
            showSurveyResultDialog(){
                if(this.showSurveyResultDialog == false){
                    this.reload = true;
                }
            },
           
            
        },
        mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
    }
</script>

<style scoped>

</style>