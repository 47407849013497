// Styles
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

export default createVuetify({
  theme: {
    themes: {
        light: {
            primary: {
                base: '#007AC3',
                lighten1: '#008EE0'
            },
            secondary: '#008EE0',
        },
    },
},
icons: {
    iconfont: 'md'
},
lang: {
    current: 'de',
},
components,
directives
})
