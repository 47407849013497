// plugins/sasp.js
import { reactive,provide } from 'vue';

export default {
  install: (app) => {
    console.log('=== Initializing SASP module ===');
    console.log('Node env:     ' + process.env.NODE_ENV);
    console.log('Base URL:     ' + process.env.BASE_URL);
    console.log('Base URL:     ' + process.env.VUE_APP_BACKEND_API_HOST_PORT);
    console.log('Backend URL:  ' + process.env.VUE_APP_BACKEND_API_BASE_URL);

    const BACKEND_API_HOST_PORT = process.env.VUE_APP_BACKEND_API_HOST_PORT;
    const BACKEND_API_CONTEXT_PATH = process.env.VUE_APP_BACKEND_API_BASE_URL;

    window.$store = reactive({
      initialized: false,
      error: {
        show: false,
        msg: null,
        source: null,
      },
      messages: [],
      messageCount: 0,
      debug: true,
      loggedIn: false,
      menu: false,
      userData: null,
      avatar: null,
      success: false,
      successMsg: null,
    });

    Object.defineProperty(app.config.globalProperties, '$store', {
      get() {
        return window.$store;
      },
      set(value) {
        window.$store.initialized = value.initialized;
        window.$store.error = value.error;
        window.$store.messages = value.messages;
        window.$store.messageCount = value.messageCount;
        window.$store.debug = value.debug;
        window.$store.loggedIn = value.loggedIn;
        window.$store.menu = value.menu;
        window.$store.userData = value.userData;
        window.$store.avatar = value.avatar;
        window.$store.success = value.success;
        window.$store.successMsg = value.successMsg;
      },
    });

    provide('$store', window.$store);

    app.config.globalProperties.msg = (msg, title = '', error = false) => {
      let count = 1;
      window.$store.messages.forEach((m, i) => {
        if (m.text === msg) {
          count += m.count;
          app.config.globalProperties.delmsg(i);
        }
      });
      window.$store.messages.unshift({ title: title, text: msg, error: error, count: count });
      window.$store.messageCount++;
    };

    app.config.globalProperties.delmsg = (index) => {
      window.$store.messages.splice(index, 1);
    };

    //error functions
    app.config.globalProperties.error = (msg, source = 'api') => {
      window.$store.error.msg = msg;
      window.$store.error.source = source;
      window.$store.error.show = true;
      //app.prototype.msg(msg, true);
    };

    app.config.globalProperties.success = (msg) => {
      window.$store.successMsg = msg;
      window.$store.success = true;
    };

    //call api functions
    app.config.globalProperties.call = (_function, _postData = null) => {
      return app.config.globalProperties.callApi(_function, _postData, window.$store.debug);
    };
    app.config.globalProperties.callApi = (_function, _postData = null, showError = false) => {
      return new Promise((resolve) => {
        let backendApiFunction = BACKEND_API_HOST_PORT + BACKEND_API_CONTEXT_PATH + `${_function}`;
        console.debug("Calling backend API function: " + backendApiFunction);

        fetch(backendApiFunction, {
          method: !_postData ? 'GET' : 'POST',
          body: !_postData ? null : JSON.stringify(_postData),
          headers: {
            'Content-Type': !_postData ? 'text/plain;charset=UTF-8' : 'application/json;charset=UTF-8',
          },
          credentials: 'include',
          cache: 'no-cache',
          redirect: 'follow',
        }).then(response => {
          console.log(response);
          if (!response.ok) {
            const error = response.statusText;
            return Promise.reject(error);
          }
          return response.json();
        }).then(res => {
          if (!res.success) {
            if (showError) {
              console.error(res.msg);
            }

            if (window.$store.loggedIn) {
              var match = res.msg.match(/^(.+?)(?: ?\(#(\d+)\))?$/);
              app.config.globalProperties.msg(match[1], `Fehler #${match[2]}`, true);
            }
          }
          resolve(res);
        }).catch(error => {
          console.error("API", error);
          app.config.globalProperties.error(`${error}`);

          //return default api response on error
          resolve({ success: false, msg: `${error}` });
        });
      });
    };

    app.config.globalProperties.loadAvatar = () => {
      window.$store.avatar = 'https://teampoll-backend-staging.uni-trier.de/img/avatars/avatar.php?id=' + window.$store.userData.id + '&t=' + Date.now();
    };

    app.config.globalProperties.status = () => {
      return app.config.globalProperties.call('users/isLoggedin').then(res => {
  
        window.$store.loggedIn = res.success;
        if (window.$store.loggedIn) {
          window.$store.userData = res.data[0];
          app.config.globalProperties.loadAvatar();
        }
        return res;
      });
    };

    console.log("sasp plugin loaded");
    //call status from here?
    app.config.globalProperties.status().then(() => {
      console.log("sasp status finished");
      window.$store.initialized = true;
      console.log("sasp plugin initialized");
    });
  }
};
