<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12" lg="8" xl="6">
                <v-card elevation="2" class="pa-4" variant="outlined" tile>
                    <v-card elevation="2" class="pa-4 mb-3 text-h6" color="blue-darken-1" dark>Passwort vergessen</v-card>
                    <v-container>
                        <v-row justify="center">
                            <v-col>
                                <p>
                                    Ihr neues Passwort wurde Ihnen per E-Mail zugesendet.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" dense>
                            <v-col md="4" class="px-4">
                                <v-btn variant="text" @click="$router.push('/login')" block>Zurück zum Login</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: [ 'token' ],
        data: function() {
            return {
                
            }
        },
        methods: {
            
        },
        mounted () {
            if(this.token != null){
                this.call('users/ResetPasswort', {
                    token: this.token,
                }).then(res => {
                    if(!res.success){
                        this.$router.push('/login');
                    }
                })
            }
            else {
                this.$router.push('/login');
            }
        }
    }
</script>

<style scoped>

</style>