<template>
    <v-container fluid v-if="renderComponent" :style="mobile ? {'padding':'unset'} : {}">
        <BoxComponent title="Offene Umfragen" >
            <p>
                Hier sehen Sie die aktuellen Umfragen, die für Sie zur Teilnahme offen sind.
            </p>
            <EditTable v-model:reload="reloadSurveys" :reload="reloadSurveys" :headers="filteredTableHeadersSurvey" api_load="surveys/ListMySurveys/2" >
                <template v-slot:default="item">
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-btn color="primary" size="small" fab @click="startSurvey(item)" v-bind="props" :disabled="!item.IsStartable4User && !item.IsActive4User" ><v-icon>{{ item.IsStartable4User ? 'mdi-clipboard-text' : item.IsActive4User ? 'mdi-clipboard-text' : 'mdi-clipboard-check'}}</v-icon></v-btn> 
                        </template>
                        <span>{{item.IsActive4User ? 'Teilnahme fortsetzen' : 'Teilnehmen'}}</span>
                    </v-tooltip>
                </template>
            </EditTable>
            <SurveyAnswerDialog v-model:show="showSurveyAnswerDialog" :show="showSurveyAnswerDialog" :survey="activeSurvey" @update:show="handleSurveyAnswerDialogUpdate"/>
            <SurveyCreateDialog v-model:show="showSurveyCreateDialog" :show="showSurveyCreateDialog" @update:show="handleSurveyCreateDialogUpdate"/>
            <SurveyEditDialog v-model:show="showSurveyEditDialog" :show="showSurveyEditDialog" :survey="activeSurvey" @update:show="handleSurveyEditDialogUpdate"/>
        </BoxComponent>
        <BoxComponent title="Ihre Umfragen">
            <p>
                Legen Sie hier eine neue Umfrage für Ihre Gruppe an oder ändern Sie bereits vorhandene Umfragen, falls diese noch nicht gestartet wurden.
                Eine Umfrage kann nur als Gruppenadmin erstellt werden.
            </p>
            <p>
                In der Übersicht sehen Sie Ihre bereits angelegten Umfragen.
            </p>
            <v-row>
                <v-col>
                        <v-tooltip location="right" :disabled="!buttonDisabled">
                            <template v-slot:activator="{ props }">
                                <span v-bind="props">
                                    <v-btn :color="buttonColor" class="ma-2 text-subtitle-2 text-capitalize" @click="showSurveyCreateDialog = true" :disabled="buttonDisabled"><v-icon start>mdi-plus</v-icon> Neue Umfrage</v-btn>
                                </span>
                            </template>
                        <span>Nur als Gruppenadministrator möglich!</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="5" v-show="error != null" justify="center">
                    <v-alert dense :type="type">
                        <strong>{{error}}</strong>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <EditTable @reload="reloadSurveys = true" v-model:reload="reload" :reload="reload" :headers="filteredCols" api_load="surveys/ListAdminSurveys">
                        <template v-slot:default="item">
                            <v-tooltip location="bottom" v-if="item.isAdmin == 1 && item.statusid == 2 && item.backToDraftavailable">
                                <template v-slot:activator="{ props }">
                                    <v-btn  size="small" fab @click="surveyDraft(item)" v-bind="props"><v-icon>mdi-undo</v-icon></v-btn> 
                                </template>
                                <span>Zum Entwurf machen</span>
                            </v-tooltip>

                            <v-tooltip location="bottom" v-if="item.statusid == 1">
                                <template v-slot:activator="{ props }">
                                    <v-btn color="green" size="small" fab @click="surveyOpen(item)" v-bind="props"><v-icon>mdi-publish</v-icon></v-btn> 
                                </template>
                                <span>Starten</span>
                            </v-tooltip>
                            <v-tooltip location="bottom" v-if="item.statusid == 1">
                                <template v-slot:activator="{ props }">
                                    <v-btn color="primary" size="small" fab @click="surveyEdit(item)" v-bind="props"><v-icon>mdi-pencil</v-icon></v-btn> 
                                </template>
                                <span>Bearbeiten</span>
                            </v-tooltip>

                            <v-tooltip location="bottom" v-if="item.statusid == 2">
                                <template v-slot:activator="{ props }">
                                    <v-btn color="orange" size="small" fab @click="surveyCloseDialog(item)" v-bind="props"><v-icon>mdi-close-octagon</v-icon></v-btn> 
                                </template>
                                <span>Abschließen</span>
                            </v-tooltip>

                            <v-tooltip location="bottom" v-if="item.statusid == 3 && item.resultsavailable">
                                <template v-slot:activator="{ props }">
                                    <v-btn  size="small" fab :to="{name: 'results_item', params: { id: item.surveyid }}" v-bind="props"><v-icon>mdi-publish</v-icon></v-btn> 
                                </template>
                                <span>Ergebnisse anzeigen</span>
                            </v-tooltip>

                            <v-tooltip location="bottom" v-if="item.statusid !== 2">
                                <template v-slot:activator="{ props }">
                                    <v-btn color="red" size="small" fab @click="surveyDeleteDialog(item)" v-bind="props"><v-icon>mdi-delete</v-icon></v-btn> 
                                </template>
                                <span>Löschen</span>
                            </v-tooltip>
                        </template>
                    </EditTable>
                </v-col>
            </v-row>

            <v-dialog v-model="confirmClose" :max-width="mobile? '90%':'33%'">
                <v-card>
                    <v-card-title class="text-h5">
                        Umfrage wirklich abschließen?
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Sind Sie sicher das die Umfrage abgeschlossen werden soll?
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" variant="text" @click="surveyClose()">Abschließen</v-btn>
                        <v-btn variant="text" @click="confirmClose = false">Abbrechen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="confirmDelete" :max-width="mobile? '90%':'33%'">
                <v-card>
                    <v-card-title class="text-h5">
                        Umfrage wirklich löschen?
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Sind Sie sicher das die Umfrage gelöscht werden soll? Dabei wird die Umfrage und <b>ALLE Ergebnisse</b> dieser Umfrage entfernt!
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" variant="text" @click="surveyDelete(); confirmDelete = false">Löschen</v-btn>
                        <v-btn variant="text" @click="confirmDelete = false">Abbrechen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </BoxComponent>
    </v-container>
</template>
<script setup>
import BoxComponent from '../components/BoxComponent.vue'
import EditTable from '../components/EditTable.vue'
import SurveyAnswerDialog from './dialogs/SurveyAnswerDialog.vue'
import SurveyCreateDialog from './dialogs/SurveyCreateDialog.vue'
import SurveyEditDialog from './dialogs/SurveyEditDialog.vue'
</script>
<script>
    export default {
        name: 'SurveysView',
        data: function() {
            return {
                buttonColor: 'primary',
                error: null,
                type: 'error',
                mobile: false,
                renderComponent: true,
                buttonDisabled: false,
                showSurveyAnswerDialog: false,
                showSurveyCreateDialog: false,
                showSurveyEditDialog: false,
                activeSurvey: null,
                activeSurveyId: null,
                reloadSurveys: false,
                confirmClose: false,
                confirmCloseId: null,
                confirmDelete: false,
                confirmDeleteId: null,
                reload: false,
                surveys: [],
            }
        },
        async created() {
                    await this.updateButtonProperties();
                    return this.call('groups/ListMyGroups');
                },
        computed: { 
            filteredCols() {
                // Define the original cols array
                const originalCols = [
                    { title: 'Aktionen', align: 'start', sortable: false, value: 'actions' },
                    { title: 'Umfrage', value: 'name' },
                    { title: 'Gruppe', value: 'groupname' },
                    { title: 'Erstellt am', value: 'createtime', type: 'datetime' },
                    { title: 'Start', value: 'startdate' },
                    { title: 'Ende', value: 'enddate'},
                    { title: 'Ist Anonym', value: 'anonym' },
                    { title: 'Anzahl Teilnehmer', value: 'countParticipants' },
                    { title: 'Status', value: 'status' }
                ];

                if (this.mobile) {
                    return originalCols.filter(col => col.title !== 'Erstellt am')
                    .filter(col => col.title !== 'Anzahl Teilnehmer')
                    .filter(col => col.title !== 'Start')
                    .filter(col => col.title !== 'Ende')
                    .filter(col => col.title !== 'Ist Anonym');
                } else {
                    return originalCols;
                }
            },
            filteredTableHeadersSurvey() {
                // Define the original cols array
                const tableHeadersSurvey = [
                    { title: 'Umfrage durchführen', align: 'start', sortable: false, value: 'actions' },
                    { title: 'Umfrage', value: 'name' },
                    { title: 'Gruppe', value: 'groupname' },
                    { title: 'Ist Anonym', value: 'anonym'},
                    { title: 'Start', value: 'startdate'},
                    { title: 'Ende', value: 'enddate'}
                ];

                if (this.mobile) {
                    return tableHeadersSurvey
                    .filter(col => col.title !== 'Start')
                    .filter(col => col.title !== 'Ende')
                } else {
                    return tableHeadersSurvey;
                }
            } 
        },
        methods: {
            async forceRender() {
                this.renderComponent = false;
                await this.$nextTick();
                this.renderComponent = true;
                await this.$nextTick();
            },
            handleSurveyEditDialogUpdate(newValue) {
                this.showSurveyEditDialog = newValue;
                if(!newValue){
                    this.forceRender();
                }
            },
            handleSurveyCreateDialogUpdate(newValue) {
                this.showSurveyCreateDialog = newValue;
                if(!newValue){
                    this.forceRender();
                }
            },
            handleSurveyAnswerDialogUpdate(newValue) {
                this.showSurveyAnswerDialog = newValue;
                if(!newValue){
                    this.forceRender();
                }
            },
            async updateButtonProperties() {
                try {
                    const isAdmin = await this.isGroupAdmin();
                    this.buttonColor = isAdmin ? 'primary' : 'grey';
                    this.buttonDisabled = !isAdmin;
                } catch (error) {
                    console.error('Error checking group admin status:', error);
                    this.buttonColor = 'grey';
                    this.buttonDisabled = true;
                }
            },
            async isGroupAdmin() {
                return await this.call('groups/IsGroupAdmin').then(res => {
                    return res.data.isAdmin;
                });
            },
            startSurvey(item){
                this.activeSurvey = item,
                this.showSurveyAnswerDialog = true;
            },
            surveyDraft(item){
                this.call('surveys/SurveyBacktoDraft', { surveyid: item.surveyid }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                    }else{
                        this.type = 'error';
                        this.error = res.msg;
                    }
                });
            },
            surveyOpen(item){
                this.call('surveys/SurveyOpen', { surveyid: item.surveyid }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                        this.forceRender();
                    }else{
                        this.type = 'error';
                        this.error = res.msg;
                    }
                });
            },
            surveyDeleteDialog(item){
                this.confirmDeleteId = item.surveyid;
                this.confirmDelete = true;
            },
            surveyDelete(){
                this.call('surveys/SurveyDelete', { surveyid: this.confirmDeleteId }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                        this.forceRender();
                    }else{
                        this.type = 'error';
                        this.error = res.msg;
                    }
                });
            },
            surveyEdit(item){
                this.activeSurvey = item;
                this.showSurveyEditDialog = true;
            },
            surveyCloseDialog(item){
                this.confirmCloseId = item.surveyid;
                this.confirmClose = true;
            },
            surveyClose(){
                this.confirmClose = false;
                this.call('surveys/SurveyClose', { surveyid: this.confirmCloseId }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                        this.reloadSurveys = true;
                        this.forceRender();
                    }else{
                        this.type = 'error';
                        this.error = res.msg;
                    }
                });
            },
            onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
            },
        },
        watch: {
            showSurveyAnswerDialog(){
                if(this.showSurveyAnswerDialog == false){
                    this.reloadSurveys = true;
                    this.reload = true;
                }
            },
            showSurveyCreateDialog(){
                if(this.showSurveyCreateDialog == false){
                    this.reloadSurveys = true;
                    this.reload = true;
                }
            },
            showSurveyEditDialog(){
                if(this.showSurveyEditDialog == false){
                    this.reloadSurveys = true;
                    this.reload = true;
                }
            },
            
        },
        mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
    }
</script>

<style scoped>

</style>