<template>
    <v-container>
        <v-form ref="changePw" v-model="changePwValid" v-on:submit.prevent="onSubmit">
            <v-row justify="center" dense>
                <v-col md="7">
                    <v-text-field id="old_password" type="password" v-model="oldPassword" :rules="oldPasswordRules" label="Aktuelles Passwort" required :disabled="attemptChange">
                        <template v-slot:append>
                            <v-icon>mdi-lock</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center" dense>
                <v-col md="7">
                    <v-text-field id="password" type="password" v-model="password" :rules="passwordRules" label="Passwort" required :disabled="attemptChange">
                        <template v-slot:append>
                            <v-icon>mdi-lock</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center" dense>
                <v-col md="7">
                    <v-text-field id="password2" type="password" v-model="password2" :rules="password2Rules" label="Passwort wiederholen" required :disabled="attemptChange">
                        <template v-slot:append>
                            <v-icon>mdi-lock</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row v-show="error != null" justify="center" dense>
                <v-col md="7">
                    <v-alert density="compact" type="error">
                        Error: <strong>{{error}}</strong>
                    </v-alert>
                </v-col>
            </v-row>
            
            <v-row justify="center">
                <v-col md="4">
                    <v-btn type="submit" color="primary" block class="ma-2 text-subtitle-2 text-capitalize"  :loading="attemptChange" @click="changePw()">Passwort speichern</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    export default {
        data: function (){
            return {
                error: null,
                oldPassword: null,
                password: null,
                password2: null,
                attemptChange: false,
                changePwValid: false,
                passwordRegEx: new RegExp(),
                oldPasswordRules: [
                    v => !!v || 'Aktuelles Passwort erforderlich'
                ],
                passwordRules: [
                    v => !!v || 'Passwort erforderlich',
                    v => !this.passwordRegEx.test(v) || 'Passwort entspricht nicht den Sicherheitseinstellungen'
                ],
                password2Rules: [
                    v => !!v || 'Passwort erforderlich',
                    v => v == this.password || 'Passwörter stimmen nicht überein'
                ],
            }
        },
        methods: {
            onSubmit() {
                console.log('Password Changed');
            },
            changePw(){
                this.error = null;
                this.attemptChange = true;
                this.call('users/ChangePassword', {
                    oldPassword: this.oldPassword,
                    newPassword: this.password,
                }).then(res => {
                    this.attemptChange = false;
                    
                    if(res.success){
                        this.success('Passwort erfolgreich geändert!');
                        
                        this.$refs.changePw.reset();
                    }
                    else {
                        this.error = res.msg;
                    }
                })
            }
        },
        mounted() {
            this.call('users/getpasswordComplexityRegex').then(res => {
                this.passwordRegEx = new RegExp(res.data.slice(1, -1));
            });
            this.$refs.changePw.reset();
        },
        created() {
        }
    }
</script>

<style scoped>

</style>