<template>
  <v-dialog v-if="survey !== null && show && surveyQuestions !== null" :model-value="show" max-width="80%" @update:value="$emit('update:show', $event)" @click:outside="$emit('update:show', false)" @click.stop>
      <v-card tile>
        <v-card-title>
            <v-row>
              <v-col cols="6" style="text-align:left">
                <span>Umfrage: {{survey.name}}</span>
              </v-col>
              <v-col cols="6" style="text-align:right">
                  <v-btn :size="mobile ? '30px': 'default'" icon @click="$emit('update:show', false)">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-col>
          </v-row>
        </v-card-title>
        <v-stepper :alt-labels="mobile" v-model="step" non-linear show-actions style="overflow-y:auto">
          <v-stepper-header>
              <v-stepper-item value="1" color="blue-darken-2" editable>Allgemeines</v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item value="2" color="blue-darken-2" editable>Ergebnisse</v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item value="3" color="blue-darken-2" editable>Exportieren</v-stepper-item>
          </v-stepper-header>
          <v-window>
          <v-stepper-window>
            <v-stepper-window-item value="1">
                <v-row>
                  <BoxComponent title="Allgemeine Informationen">
                    <v-row>
                      <v-col>
                        <v-text-field :model-value="survey.name" label="Beratungspunkt" readonly></v-text-field>
                        <v-text-field :model-value="survey.description" label="Art der Entscheidung" readonly></v-text-field>
                        <v-text-field :model-value="survey.groupname" label="Gruppe" readonly></v-text-field>
                        <v-text-field :model-value="survey.creator.email" label="Ersteller" readonly></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field :model-value="surveyQuestionsIds" label="Anzahl der Fragen" readonly></v-text-field>
                        <v-text-field :model-value="survey.startdate" label="Umfragestart" readonly></v-text-field>
                        <v-text-field :model-value="survey.enddate" label="Umfrageende" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </BoxComponent>
                </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item value="2">
               <v-row v-for="(item) in surveyQuestions" :key="item.id" dense>
                <v-col>
                  <v-container fluid class="py-0" >
                    <v-row dense>
                      <v-col class="font-weight-bold bg-blue-darken-1 text-black elevation-1">
                        {{ item.text }}
                      </v-col>
                    </v-row>
                    <v-row v-for="answer in item.answers" :key="answer.id" dense>
                      <v-col cols="2" style="max-width: content; flex: 1;">  
                        {{answer.text}}         
                      </v-col>
                      <v-col>
                         <v-progress-linear :model-value="answer.percentage*100" height="25" color="blue-lighten-1">
                          <strong class="pr-1">{{ answer.count }}</strong> ({{Math.ceil(answer.percentage*100)}}%)
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
               </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item value="3">
              <v-row>
                <BoxComponent title="Herunterladen der Ergebnisse" class="text-center">
                  <v-col>
                    <v-btn color="Blue" class="ma-2 text-subtitle-2 text-capitalize" @click="downloadResults(true)"><v-icon start>mdi-download</v-icon>Anonyme Ergebnisse herunterladen</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn v-if="survey.anonym == 'Nein'" color="Blue" class="ma-2 text-subtitle-2 text-capitalize" @click="downloadResults(false)"><v-icon start>mdi-download</v-icon>Ergebnisse herunterladen</v-btn>
                  </v-col>
                </BoxComponent>
              </v-row>
          </v-stepper-window-item>
          </v-stepper-window>
          <v-row>
            <v-col :cols="step==2?'4':'6'" style="text-align:left;align-content: end">
                <v-btn variant="text" disabled @click="step = 0" v-if="step == 0"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                <v-btn variant="text" @click="step = 0" v-if="step == 1"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                <v-btn variant="text" @click="step = 1" v-if="step == 2"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
            </v-col>
            <v-col :cols="step==2?'8':'6'" style="text-align:right">
              <v-btn variant="text" v-if="step == 0" @click="step = 1" location="right">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                <v-btn variant="text" v-if="step == 1" @click="step = 2" location="right">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                <v-btn color="primary" v-if="step == 2" dark class="ma-2 text-subtitle-2 text-capitalize" @click="endSurvey()"><v-icon start>mdi-check</v-icon> Ansicht schließen</v-btn>
            </v-col>
        </v-row>
      </v-window>
        </v-stepper>
      </v-card>
    </v-dialog>
</template>
<script setup>
import BoxComponent from '../../components/BoxComponent.vue'
</script>
<script>
export default {
  props: [ 'show', 'survey' ],
  data: function() {
    return {
      surveyQuestions: null,
      surveyQuestionsIds: null,
      step: 0,
      mobile: false,
    };
  },
  methods: {
    sanitizeFilename(filename) {
      const invalidChars = /[/:*?"<>|]/g;
      let sanitizedFilename = filename.replace(invalidChars, '');
      sanitizedFilename = sanitizedFilename.trim();

      return sanitizedFilename;
    },
    onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
        },
    downloadResults($isAnonym){
      var csv = "Beratungspunkt;Art der Entscheidung;Startdatum;Enddatum\n";
      this.call("surveys/GetSurveyByID/",{ surveyid: this.survey.surveyid }).then(res => {
        const startdate = new Date(res.data[0].startdate*1000);
        const formattedStartdate = startdate.getFullYear()+"-"+String(startdate.getMonth()+1).padStart(2,'0')+"-"+String(startdate.getDate()).padStart(2,'0');
        const enddate = new Date(res.data[0].startdate*1000);
        const formattedeEnddate = enddate.getFullYear()+"-"+String(enddate.getMonth()+1).padStart(2,'0')+"-"+String(enddate.getDate()).padStart(2,'0');
        csv = csv.concat(res.data[0].name+";"+res.data[0].description+";"+formattedStartdate+";"+formattedeEnddate+"\n");
          this.call("surveys/GetUsersParticipatedInfo/", {surveyid: this.survey.surveyid }).then(resp => {
            csv = csv.concat("Teilnehmer:; ");
            for (const [, mail] of Object.entries(resp.data)) {
              csv = csv.concat(mail.email.replace(/deleted@deleted\.deleted/g, "gelöschter Account")+";");
            }
            csv = csv.concat("\n\nFrage;Antwort;Anzahl Stimmen; Prozentualer Anteil\n");
            this.call("surveys/getSurveyQuestionResult/", { surveyid: this.survey.surveyid, withUsers: !$isAnonym}).then(response => {
              for (const [, q] of Object.entries(response.data)) {
                csv = csv.concat(q.text+"\n");
                for (const [, answer] of Object.entries(q.answers)) {
                  if($isAnonym){
                    csv = csv.concat(";"+answer.text+";"+answer.count+";"+answer.percentage*100+"%\n");
                  }else{
                    let email;
                    if(answer.email != null) {
                      email = answer.email.replace(/deleted@deleted\.deleted/g, "gelöschter Account");
                    }else{
                      email = "";
                    }
                    csv = csv.concat(";"+answer.text+";"+answer.count+";"+answer.percentage*100+"%;"+email+"\n");
                  }
                }
              }
              var hiddenElement = document.createElement('a');

              hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI('\uFEFF'+csv);
              hiddenElement.target = '_blank';
              
              hiddenElement.download = this.sanitizeFilename(res.data[0].name)+'.csv';
              hiddenElement.click();
            })
          })
      });
    
    },
    async startSurvey(){
      this.step = 0;
      this.surveyQuestions = [];

      this.call("surveys/getSurveyQuestionResult/", { surveyid: this.survey.surveyid }).then(res => {
        this.surveyQuestionsIds = Object.entries(res.data).length;
        this.surveyQuestions = res.data;
        console.log("Open survey dialog");
        this.$forceUpdate();
      })
    },

    endSurvey(){
          this.$emit('update:show', false);
    },
  },
  watch: {
    show(){
        if(this.show && this.survey != null){
            this.startSurvey();
        }
    }
  },
  mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
}
</script>
<style>
.v-stepper--alt-labels .v-stepper-item{
    flex-basis: 0px;
}
</style>