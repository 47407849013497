<template>
  <v-container fluid v-if="$store.userData !== null" :style="mobile ? {'padding':'unset'} : {}">
    <BoxComponent :title="`Herzlich Willkommen, ${$store.userData.title != null ? `${$store.userData.title} ` : ''}${$store.userData.firstname} ${$store.userData.lastname}!`" >
      <p>
        Erstellen Sie für Ihre Gruppe eine eigene Umfrage – schnell und unkompliziert.
        Erstellen Sie gruppenbezogene Umfragen schnell und unkompliziert. Legen Sie dafür im Menü unter "Gruppen" eine Gruppe an und laden Sie alle Teilzunehmenden zu dieser ein.
        Umfragen können im Menü unter "Umfragen" erstellt und verwaltet werden, während die Ergebnisse einer beendeten Umfrage unter "Ergebnisse" zu finden sind.
      </p>
      <p>
        Viel Spaß und Erfolg dabei!
      </p>
    </BoxComponent>
    <BoxComponent title="Teams" v-show="showInvites">
      <InvitesTable v-model="showInvites" />
    </BoxComponent>
    <BoxComponent title="Offene Umfragen">
      <p>
        Hier sehen Sie die aktuellen Umfragen, die für Sie zur Teilnahme offen sind.
      </p>
      <EditTable v-model:reload="reloadSurveys" :reload="reloadSurveys" :headers="filteredCols" api_load="surveys/ListMySurveys/2" >
        <template v-slot:default="item">
          <v-tooltip location="bottom">
              <template v-slot:activator="{ props }"><!-- :disabled="!item.IsActive4User" -->
                  <v-btn color="primary" size="small" fab @click="startSurvey(item)" v-bind="props" :disabled="!item.IsStartable4User && !item.IsActive4User" ><v-icon>{{ item.IsStartable4User ? 'mdi-clipboard-text' : item.IsActive4User ? 'mdi-clipboard-text' : 'mdi-clipboard-check'}}</v-icon></v-btn> 
              </template>
              <span>{{item.IsActive4User ? 'Teilnahme fortsetzen' : 'Teilnehmen'}}</span>
          </v-tooltip>
      </template>
      </EditTable>
      <SurveyAnswerDialog v-model:show="showSurveyAnswerDialog" :show="showSurveyAnswerDialog" :survey="activeSurvey" />
    </BoxComponent>
  </v-container>
</template>
<script setup>
import BoxComponent from '../components/BoxComponent.vue'
import EditTable from '../components/EditTable.vue'
import SurveyAnswerDialog from './dialogs/SurveyAnswerDialog.vue'
import InvitesTable from './tables/InvitesTable.vue'
</script>
<script>
export default {
  name: 'HomeView',
  data: function() {
    return {
      mobile: false,
      showInvites: false,
      showSurveyAnswerDialog: false,
      activeSurvey: null,
      reloadSurveys: false,
    };
  },
  mounted() {
    this.mobile = window.innerWidth <= 920 ? true : false;
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
      this.mobile = window.innerWidth <= 920 ? true : false;
    },
    startSurvey(item){
      this.activeSurvey = item,
      this.showSurveyAnswerDialog = true;
    }
  },
  watch: {
    showSurveyAnswerDialog(){
      if(this.showSurveyAnswerDialog == false){
        this.reloadSurveys = true;
      }
    }
  },
  computed: {
    filteredCols() {
      // Define the original cols array
      const originalCols = [
        { title: 'Umfrage durchführen', sortable: false, align: 'start', value: 'actions' },
          { title: 'Umfrage', value: 'name' },
          { title: 'Gruppe', value: 'groupname' },
          { title: 'Start', value: 'startdate'},
          { title: 'Ende', value: 'enddate'},
          { title: 'Ist Anonym', value: 'anonym' }
      ];

      if (this.mobile) {
          return originalCols
          .filter(col => col.title !== 'Start')
          .filter(col => col.title !== 'Ende')
      } else {
          return originalCols;
      }
  },
}
}
</script>