<template>
    <v-container fluid :style="mobile ? {'padding':'unset'} : {}">
        <BoxComponent title="Gruppen">
            <p>
                Legen Sie hier Ihre (Projekt-)Gruppe an. Wenn Sie eine neue Gruppe anlegen, werden Sie automatisch Gruppenleitung, d.h. Sie können Mitglieder zu Ihrer Gruppe hinzufügen und Umfragen erstellen.
            </p>
            <InvitesTable @reload="reload = true" />
                <v-tooltip location="right" :disabled="!buttonDisabled">
                    <template v-slot:activator="{ props }">
                        <span v-bind="props">
                            <v-btn :color="buttonColor" class="ma-2 text-subtitle-2 text-capitalize" @click="create.show = true; error=null;" :disabled="buttonDisabled"><v-icon start>mdi-plus</v-icon> Neu</v-btn>
                        </span>
                    </template>
                <span>Nur als Universitätsmitarbeiter möglich!</span>
            </v-tooltip>
            <v-col md="5" v-show="error != null && create.show == false && edit.show == false" justify="center">
                <v-alert dense :type="type">
                    <strong>{{error}}</strong>
                </v-alert>
            </v-col>
            <EditTable v-model:reload="reload" :reload="reload" :headers="cols" v-model="groups" api_load="groups/GetMyGroups" >
                <template v-slot:default="item">
                    <v-tooltip v-if="item.isAdmin" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-btn color="primary" size="small" fab @click="showEdit(item); error=null;" v-bind="props"><v-icon>mdi-pencil</v-icon></v-btn> 
                        </template>
                        <span>Bearbeiten</span>
                    </v-tooltip>
                    <v-tooltip v-else location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-btn color="red" size="small" fab @click="showLeaveGroup(item)" v-bind="props"><v-icon>mdi-cancel</v-icon></v-btn> 
                        </template>
                        <span>Gruppe verlassen</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.isAdmin" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-btn color="red" size="small" fab @click="showDelete(item)" v-bind="props"><v-icon>mdi-delete</v-icon></v-btn> 
                        </template>
                        <span>Löschen</span>
                    </v-tooltip>
                </template>
            </EditTable>
            <v-dialog v-model="create.show" max-width="80%">
                <v-sheet width="100%" v-model="error" v-if="error !=null" style="margin:2px; text-align:center ;align-self:center;">
                    <v-alert dense :type="type" style="margin:0px">
                        <strong>{{error}}</strong>
                    </v-alert>
                </v-sheet>
                <v-card tile>
                    <v-card-title>
                        <v-row>
                            <v-col cols="6" style="text-align:left">
                                <span>Neue Gruppe erstellen</span>
                            </v-col>
                            <v-col cols="6" style="text-align:right">
                            <v-btn :size="mobile ? '30px': 'default'" icon @click="create.show = false; error=null">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row> 
                    </v-card-title>

                        <v-stepper :alt-labels="mobile" v-model="create.value" non-linear show-actions style="overflow-y:auto">
                            <v-stepper-header>
                                <v-stepper-item value="1" color="blue-darken-2" :complete="create.teamDataValid">Gruppe</v-stepper-item>
                                <v-divider></v-divider>
                                <v-stepper-item value="2" color="blue-darken-2" :complete="create.members.length > 0 && create.membersValid">Mitglieder</v-stepper-item>
                                <v-divider></v-divider>
                                <v-stepper-item value="3" color="blue-darken-2" >Fertigstellen</v-stepper-item>
                            </v-stepper-header>
                            <v-window>
                            <v-stepper-window>
                                <v-stepper-window-item value="1">
                                    <v-form v-model="create.teamDataValid" v-on:submit.prevent="() => {}">
                                        <v-row dense v-for="(field, i) in create.fields" :key="i">
                                            <v-col :cols="mobile?'12':'4'">
                                                <v-list-subheader>{{field.text}}</v-list-subheader>
                                            </v-col>
                                            <v-col :cols="mobile?'12':'default'">
                                                <v-text-field v-model="field.value" :rules="field.rules" required></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-stepper-window-item>
                                <v-stepper-window-item value="2">
                                    <v-form v-model="create.membersValid">
                                        <v-combobox v-model="create.members" label="Gruppenmitglieder einladen" placeholder="E-Mail von Mitgliedern" :loading="create.loading" :disabled="create.loading" append-icon="" multiple chips closable-chips disable-lookup :rules="[inviteRules]" required :delimiters="[' ', ',', ';']" />
                                    </v-form>
                                </v-stepper-window-item>
                                <v-stepper-window-item value="3">
                                    <v-row>
                                        <v-col :cols="mobile?'12':'4'">
                                            <BoxComponent title="Allgemeine Informationen">
                                                <v-text-field v-for="(field, i) in create.fields" :key="i" v-model="field.value" :placeholder="field.text" :rules="field.rules" readonly disabled></v-text-field>  
                                            </BoxComponent>
                                        </v-col>
                                        <v-col>
                                            <BoxComponent title="Eingeladene Gruppenmitglieder">
                                                <v-table>
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                E-Mail
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,i) in create.members" :key="i">
                                                            <td>{{ item }}</td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-table>
                                            </BoxComponent>                                     
                                        </v-col>
                                    </v-row>
                                </v-stepper-window-item>
                            </v-stepper-window>
                                <v-row>
                                    <v-col :cols="step==2?'4':'6'" style="text-align:left;align-content: end">
                                        <v-btn variant="text" v-if="create.value == 0" @click="create.value = 0" ><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                                        <v-btn variant="text" v-if="create.value == 1" @click="create.value = 0"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                                        <v-btn variant="text" v-if="create.value == 2" @click="create.value = 1"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                                    </v-col>
                                    <v-col :cols="step==2?'8':'6'" style="text-align:right">
                                        <v-btn variant="text" v-if="create.value == 0" @click="create.value = 1" location="right" :disabled="!create.teamDataValid">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn> 
                                        <v-btn variant="text" v-if="create.value == 1" @click="create.value = 2" :disabled="create.members.length == 0 || !create.membersValid">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                                        <v-btn color="primary" v-if="create.value == 2" dark class="ma-2 text-subtitle-2 text-capitalize" @click="createGroup()" :loading="create.loading" :disabled="create.loading"><v-icon start>mdi-content-save</v-icon> Speichern</v-btn>
                                    </v-col>
                                </v-row>
                            </v-window>
                        </v-stepper>
                   
                </v-card>
            </v-dialog>

            <v-dialog v-model="edit.show" max-width="80%">
                <v-sheet width="100%" v-model="error" v-if="error !=null" style="margin:2px; text-align:center ;align-self:center;">
                    <v-alert dense :type="type" style="margin:0px">
                        <strong>{{error}}</strong>
                    </v-alert>
                </v-sheet>
                <v-card tile>
                    <v-card-title>
                        <v-row>
                            <v-col cols="6" style="text-align:left">
                                <span>Gruppe bearbeiten</span>
                            </v-col>
                            <v-col cols="6" style="text-align:right">
                            <v-btn :size="mobile ? '30px': 'default'" icon @click="edit.show = false; error=null">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row> 
                    </v-card-title>
                        <v-stepper :alt-labels="mobile" v-model="edit.value" non-linear show-actions style="overflow-y:auto">
                            <v-stepper-header>
                                <v-stepper-item value="1" color="blue-darken-2" :complete="edit.teamDataValid">Gruppe</v-stepper-item>
                                <v-divider></v-divider>
                                <v-stepper-item value="2" color="blue-darken-2" :complete="edit.members.length > 0 && edit.inviteMembersValid">Mitglieder</v-stepper-item>
                                <v-divider></v-divider>
                                <v-stepper-item value="3" color="blue-darken-2">Fertigstellen</v-stepper-item>
                            </v-stepper-header>
                            <v-window>
                            <v-stepper-window>
                                <v-stepper-window-item value="1">
                                    <v-form v-model="edit.teamDataValid" v-on:submit.prevent="() => {}">
                                        <v-row dense v-for="(field, i) in edit.fields" :key="i">
                                            <v-col cols="4">
                                                <v-list-subheader>{{field.text}}</v-list-subheader>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-model="field.value" :rules="field.rules" required></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-stepper-window-item>
                                <v-stepper-window-item value="2">
                                    <v-form v-model="edit.inviteMembersValid">
                                        <v-combobox v-model="edit.inviteMembers" label="Gruppenmitglieder einladen" placeholder="E-Mail Adressen von Gruppenmitgliedern" :loading="edit.loading" :disabled="edit.loading" append-icon="" multiple chips closable-chips disable-lookup :rules="[inviteRulesEdit]" required :delimiters="[' ', ',', ';']" />
                                    </v-form>
                                    <v-autocomplete v-model="edit.members" :loading="edit.loading" :disabled="edit.loading" :items="edit.actualMembers" chips label="Gruppenmitglieder" item-title="email" item-value="email" :filter="memberFilter" :rules="[notEmpty]" return-object auto-select-first multiple>
                                        <template v-slot:chip="data">
                                            <v-chip v-bind="data.props" :closable="data.item.raw.email != $store.userData.email" @click:close="remove(edit.admins, data.item.raw, 'email');" :disabled="data.item.raw.email == $store.userData.email">
                                                {{data.item.raw.email}}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <v-list-item v-show="data.item.raw.email != $store.userData.email" v-bind="data.props" :title="null" @click.stop>
                                                <v-list-item>
                                                    <v-list-item-title>{{ data.item.raw.firstname }} {{ data.item.raw.lastname }}</v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.raw.email"></v-list-item-subtitle>
                                                </v-list-item>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>

                                    <v-autocomplete v-model="edit.admins" :loading="edit.loading" :disabled="edit.loading" :items="edit.members" chips label="Gruppenleitung" item-title="email" item-value="email" :Filter="memberFilter" :rules="[notEmpty]" return-object auto-select-first multiple>
                                        <template v-slot:chip="data">
                                            <v-chip v-bind="data.props" :closable="data.item.raw.email != $store.userData.email" @click:close="remove(edit.admins, data.item.raw, 'email')" :disabled="data.item.raw.email == $store.userData.email">
                                                {{data.item.raw.email}}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <v-list-item v-show="data.item.raw.email != $store.userData.email" v-bind="data.props" :title="null">
                                                    <v-list-item-title>{{ data.item.raw.firstname }} {{ data.item.raw.lastname }}</v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.raw.email"></v-list-item-subtitle>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                    <v-row v-show="showMissingUserError" v-html="missingUser"></v-row>
                                </v-stepper-window-item>
                                <v-stepper-window-item value="3">
                                    <v-row>
                                        <v-col :cols="mobile?'12':'4'">
                                            <BoxComponent title="Allgemeine Informationen">
                                                <v-text-field v-for="(field, i) in edit.fields" :key="i" v-model="field.value" :placeholder="field.text" :rules="field.rules" readonly disabled></v-text-field>  
                                            </BoxComponent>
                                        </v-col>
                                        <v-col>
                                            <BoxComponent title="Eingeladene Gruppenmitglieder">
                                                <v-table>
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                E-Mail
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,i) in edit.inviteMembers" :key="i">
                                                            <td>{{ item }}</td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-table>
                                            </BoxComponent>
                                            <BoxComponent title="Gruppenmitglieder">
                                                <v-table>
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                E-Mail
                                                            </th>
                                                            <th class="text-left">
                                                                Name
                                                            </th>
                                                            <th class="text-left">
                                                                Vorname
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,i) in edit.members" :key="i">
                                                            <td>{{ item.email }}</td>
                                                            <td>{{ item.lastname }}</td>
                                                            <td>{{ item.firstname }}</td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-table>
                                            </BoxComponent>
                                            <BoxComponent title="Gruppenleitung">
                                                <v-table>
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                E-Mail
                                                            </th>
                                                            <th class="text-left">
                                                                Name
                                                            </th>
                                                            <th class="text-left">
                                                                Vorname
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,i) in edit.admins" :key="i">
                                                            <td>{{ item.email }}</td>
                                                            <td>{{ item.lastname }}</td>
                                                            <td>{{ item.firstname }}</td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-table>
                                            </BoxComponent>
                                        </v-col>
                                    </v-row>
                                </v-stepper-window-item>
                            </v-stepper-window>
                            <v-row>
                                <v-col :cols="step==2?'4':'6'" style="text-align:left;align-content: end">
                                    <v-btn variant="text" v-if="edit.value == 0" @click="edit.value = 0" ><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                                    <v-btn variant="text" v-if="edit.value == 1" @click="edit.value = 0"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                                    <v-btn variant="text" v-if="edit.value == 2" @click="edit.value = 1"><v-icon>mdi-arrow-left</v-icon> Zurück</v-btn>
                                </v-col>
                                <v-spacer />
                                <v-col :cols="step==2?'8':'6'" style="text-align:right">
                                    <v-btn variant="text" v-if="edit.value == 0" @click="edit.value = 1" location="right" :disabled="!edit.teamDataValid">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                                    <v-btn variant="text" v-if="edit.value == 1" @click="edit.value = 2" :disabled="edit.members.length == 0 || edit.admins.length == 0 || !edit.inviteMembersValid || missmatchAdmin">Weiter <v-icon>mdi-arrow-right</v-icon></v-btn>
                                    <v-btn color="primary" v-if="edit.value == 2" dark class="ma-2 text-subtitle-2 text-capitalize" @click="editGroup()" :loading="edit.loading" :disabled="edit.loading"><v-icon start>mdi-content-save</v-icon> Speichern</v-btn>
                                </v-col>
                            </v-row>
                        </v-window>
                    </v-stepper>
                </v-card>
            </v-dialog>
            <v-dialog v-model="confirmDelete" :max-width="mobile? '90%':'33%'">
                <v-card>
                    <v-card-title class="text-h5">
                        Gruppe wirklich löschen?
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Sind Sie sicher das die Gruppe gelöscht werden soll?
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" variant="text" @click="groupDelete(); confirmDelete = false">Löschen</v-btn>
                        <v-btn variant="text" @click="confirmDelete = false">Abbrechen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="confirmLeave" :max-width="mobile? '90%':'33%'">
                <v-card>
                    <v-card-title class="text-h5">
                        Gruppe wirklich verlassen?
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Sind Sie sicher das Sie die Gruppe verlassen möchten?
                            Bereits in der Gruppe teilgenommene Umfragen bleiben bestehen.
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" variant="text" @click="leaveGroup(); confirmLeave = false">Verlassen</v-btn>
                        <v-btn variant="text" @click="confirmLeave = false">Abbrechen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </BoxComponent>
        <BoxComponent title="Einladung zur Registrierung">
            <p>
                Laden Sie Uni-Externe Personen zur Registrierung in Teampoll ein, um diesen die Teilnahme an Umfragen zu ermöglichen. 
            </p>
            <v-form v-model="inviteFormValid" v-on:submit.prevent="invite">
                <v-text-field id="email" v-model="email" :rules="emailRules" label="E-Mail Adresse" required>
                    <template v-slot:append>
                        <v-icon>mail_outline</v-icon>
                    </template>
                </v-text-field>
                <br>
                    <v-row>
                    <v-tooltip right :disabled="!buttonDisabled">
                        <template v-slot:activator>
                            <span>
                                <v-btn type="submit" @click.prevent="invite()" :color="buttonColor" class="ma-4 text-subtitle-2 text-capitalize" :disabled="buttonDisabled || !inviteFormValid"><v-icon start>mdi-plus</v-icon> Einladen</v-btn>
                            </span>
                        </template>
                        <span>Nur als Universitätsmitarbeiter möglich!</span>
                    </v-tooltip>
                    <v-col md="7" v-show="message != null" justify="center" style="margin:8px; padding:0px">
                        <v-alert dense :type="type" style="margin:0px">
                            <strong>{{message}}</strong>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </BoxComponent>
    </v-container>
</template>
<script setup>
import BoxComponent from '../components/BoxComponent.vue'
import EditTable from '../components/EditTable.vue'
import InvitesTable from './tables/InvitesTable.vue';
</script>
<script>
    export default {
        name: 'TeamsView',
        data: function(){
            return {
                missmatchAdmin:false,
                showMissingUserError:false,
                missingUser:"Es muss mindestens Ihre Email als Leitung und Mitglied angegeben werden!",
                type:"error",
                error: null,
                inviteFormValid: false,
                message: null,
                mobile: false,
                email: null,
                emailRules: [
                    v => !!v || 'E-Mail erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail nicht gültig',
                    v => (v && v.length <= 50) || 'Email darf maximal 50 Zeichen lang sein',
                ],
                buttonColor: 'grey',
                buttonDisabled: false,
                confirmDelete: false,
                confirmDeleteId: null,
                confirmLeave: false,
                confirmLeaveId: null,
                reload: false,
                groups: null,
                create: {
                    show: false,
                    value: 0,
                    fields: [
                        { text: 'Name', var: 'name', value: null, rules: [ v => !!v || 'Name erforderlich' ,v => (v && v.length <= 50) || 'Name darf maximal 50 Zeichen lang sein'] },
                        { text: 'Beschreibung', var: 'description', value: null, rules: [ v => !!v || 'Beschreibung erforderlich' ] },
                    ],
                    teamDataValid: false,
                    loading: false,
                    members: [],
                    membersValid: false,
                },
                edit: {
                    show: false,
                    value: 0,
                    fields: [
                        { text: 'Name', var: 'name', value: null, rules: [ v => !!v || 'Name erforderlich' ,v => (v && v.length <= 50) || 'Name darf maximal 50 Zeichen lang sein'] },
                        { text: 'Beschreibung', var: 'description', value: null, rules: [ v => !!v || 'Beschreibung erforderlich' ] },
                    ],
                    teamDataValid: false,
                    loading: false,
                    actualMembers: [],
                    members: [],
                    actualAdmins: [],
                    admins: [],
                    actualInvitedMembers: [],
                    inviteMembers: [],
                    inviteMembersValid: false,
                    item: null,
                },
                cols: [
                    { title: 'Aktionen', align: 'start', sortable: false, value: 'actions' },
                    { title: 'Name', value: 'name' },
                    { title: 'Beschreibung', value: 'description' }
                ],
            }
        },
        methods: {
            preventBackspaceDeletion(event) {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        console.log("----------------");
        event.stopPropagation();
      }
    },
            invite(){
                this.call('users/sendInviteToRegister', {
                    email: this.email.trim()
                }).then(res => {   
                    if(res.success){
                        this.status().then(res => {
                            if(res.success){
                                this.type = "success";
                                this.message = "Einladung erfolgreich!";
                            }
                        });
                    }
                    else {
                        this.type = "error";
                        this.message = res.msg;
                    }
                })
            },
            async updateButtonProperties() {
                try {
                    const isEmployee = await this.isEmployee();
                    this.buttonColor = isEmployee ? 'primary' : 'grey';
                    this.buttonDisabled = !isEmployee;
                } catch (error) {
                    console.error('Error checking employee status:', error);
                    this.buttonColor = 'grey';
                    this.buttonDisabled = true;
                }
            },
            async isEmployee() {
                return await this.call('users/isEmployee').then(res => {
                    return res.data.isEmployee == 1 ? true : false;
                });
            },
            leaveGroup(){
                this.call('groups/RemoveUserFromGroup', { groupid: this.confirmLeaveId.id, user: this.confirmLeaveId.createdby, leave: "NOTNULL" }).then(res =>{
                    if(res.success){
                        //reload table
                        this.reload = true;
                    }else {
                        this.type = "error";
                        this.error = res.msg;
                    }
                });
            },
            remove(array, item, key) {
                var index = array.findIndex((e) => { return e[key] == item[key]; });
                if (index >= 0) array.splice(index, 1);
            },
            memberFilter(item, queryText) { 
                return item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                    item.lastname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                    item.firstname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
                ;
            },
            groupDelete(){
                this.call('groups/DeleteGroup', { groupid: this.confirmDeleteId }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                    }else{
                        this.type = "error";
                        this.error = res.msg;
                    }
                });
            },
            createGroup(){
                this.create.loading = true;

                var postData = {};
                this.create.fields.forEach(field => {
                    if(!field.disabled && field.value != null){
                        postData[field.var] = field.value;
                    }
                });
                postData.emails = this.create.members;

                this.call('groups/CreateGroup', postData).then(res => {
                    if(res.success){

                        this.create.show = false,
                        this.create.value = 0;
                        this.create.members = [];
                        this.create.fields.forEach(field => {
                            field.value = null
                        });
                        this.create.teamDataValid = false;
                        this.create.membersValid = false;
                        this.reload = true;
                    }
                    else {
                        this.type = "error";
                        this.error = res.msg;
                    }
                    this.create.loading = false;
                });
            },
            showEdit(item){
                this.edit.item = item;
                this.edit.fields.forEach(field => {
                    field.value = item[field.var];
                });
                this.members = [];
                this.edit.value = 0;
                this.edit.show = true;
                this.error = null;
            },
            showDelete(item){
                this.confirmDeleteId = item.id;
                this.confirmDelete = true;
            },
            showLeaveGroup(item){
                this.confirmLeaveId = item;
                this.confirmLeave = true;
            },
            editGroup(){
                this.edit.loading = true;
                var add = [];
                var remove = [];
                //get add
                this.edit.members.forEach(member => {
                    if(this.edit.actualMembers.find(m => m.id === member.id) == null){
                        add.push(member.id);
                    }
                });
               
                //get remove
                this.edit.actualMembers.forEach(member => {
                    if(this.edit.members.find(m => m.id === member.id ) == null){
                        remove.push(member.id);
                        if(member.isAdmin){
                            this.remove(this.edit.actualAdmins, member, 'email');
                        }
                    }
                });

                var setAdmin = [];
                var addAdmin = [];
                var removeAdmin = [];
                //get add aminds
                this.edit.admins.forEach(admin => {
                    if(this.edit.actualAdmins.find(m => m.id === admin.id) == null){
                        if(add.find(a => a === admin.id) == null){
                            setAdmin.push(admin.id);
                        }
                        else {
                            addAdmin.push(admin.id);
                        }
                    }
                });
                
                //get remove admins
                this.edit.actualAdmins.forEach(admin => {
                    if(this.edit.admins.find(m => m.id === admin.id ) == null){
                        removeAdmin.push(admin.id);
                    }
                });

                var addInvites = [];
                var removeInvites = [];
                //get added invites
                this.edit.inviteMembers.forEach(member => {
                    if(this.edit.actualInvitedMembers.find(m => m === member) == null){
                        addInvites.push(member);
                    }
                });
               
                //get removed invites
                this.edit.actualInvitedMembers.forEach(member => {
                    if(this.edit.inviteMembers.find(m => m === member) == null){
                        removeInvites.push(member);
                    }
                });

                var postData = {};
                this.edit.fields.forEach(field => {
                    if(!field.disabled && field.value != null){
                        postData[field.var] = field.value;
                    }
                });
                postData.groupid = this.edit.item.id;

                this.call('groups/SetGroup', postData).then(res => {
                    if(res.success){
                        add.forEach(id => {
                            this.call('groups/AddUserToGroup', { groupid: this.edit.item.id, userid: id }).then(res => {
                                if(res.success){
                                       if(addAdmin.find(a => a === id) != null){
                                           this.call('groups/MakeUserAdminInGroup', { groupid: this.edit.item.id, userid: id });
                                       }
                                }
                            });
                        });

                        remove.forEach(id => {
                            this.call('groups/RemoveUserFromGroup', { groupid: this.edit.item.id, userid: id });
                        });

                        setAdmin.forEach(id => {
                            this.call('groups/MakeUserAdminInGroup', { groupid: this.edit.item.id, userid: id });
                        });

                        removeAdmin.forEach(id => {
                            this.call('groups/RemoveUserAdminInGroup', { groupid: this.edit.item.id, userid: id });
                        });

                        if(addInvites.length > 0){
                            this.call('groups/AddUserToGroup', { groupid: this.edit.item.id, emails: addInvites });
                        }

                        removeInvites.forEach(email => {
                            this.call('groups/RemoveUserInvite', { groupid: this.edit.item.id, email: email });
                        });

                        this.edit.show = false;
                        this.reload = true;
                    }
                    else {
                        this.type = "error";
                        this.error = res.msg;
                    }
                    this.edit.loading = false;
                });
            },
            inviteRules(value){
                if(value.length == 0)
                    return 'Geben Sie mindestens ein Mitglied an';
                for(var i in value){
                    console.log(`${value[i]} -> ${/.+@.+\..+/.test(value[i])}`);
                    if(!/.+@.+\..+/.test(value[i])){
                        return 'E-Mail nicht gültig';
                    }
                }
                return true;
            },
            notEmpty(value){
                this.missmatchAdmin = false;
                let set = new Set(this.edit.members);
                let matching = false;
                for(const value of this.edit.admins){
                    if(set.has(value)){
                        matching = true;
                    }
                }
                if(value.length == 0 || !matching){
                    this.missmatchAdmin = true;
                    return 'Geben Sie mindestens eine Email an die sowohl Mitglied als auch der Leitung zugehört!';
                }
                return true;
            },
            inviteRulesEdit(value){
                for(var i in value){
                    if(!/.+@.+\..+/.test(value[i])){
                        return 'E-Mail nicht gültig';
                    }
                    else if(this.edit.members.find(m => m.email == value[i]) != null){
                        return 'Ist bereits Mitglied';
                    }
                }
                return true;
            },
            onResize() {
                this.mobile = window.innerWidth <= 920 ? true : false;
            },
        },
        watch: {
            'create.value'() {
                this.error = null;
                this.$forceUpdate();
            },
            'edit.value'() {
                if(this.edit.value == 1)
                {
                    this.edit.loading = true;
                    this.edit.inviteMembers = [];
                    this.edit.actualInvitedMembers = [];
                    this.edit.members = [];
                    this.edit.actualMembers = [];
                    this.edit.admins = [];
                    this.edit.actualAdmins = [];
                    this.call('groups/GetGroupInvites', { groupid: this.edit.item.id }).then(res => {
                        if(res.success){
                            res.data.forEach(member => {
                                this.edit.inviteMembers.push(member.email);
                                this.edit.actualInvitedMembers.push(member.email);
                            });
                        }
                    
                            this.call('groups/GetUsersByGroupId',{ id: this.edit.item.id }).then(res => {
                                if(res.success && res.data != null){
                                    this.edit.members = res.data;
                                    this.edit.actualMembers = [];
                                    this.edit.members.forEach(member => {
                                        this.edit.actualMembers.push(member);
                                        if(member.isAdmin){
                                            this.edit.admins.push(member);
                                            this.edit.actualAdmins.push(member);
                                        }
                                    });
                                }
                                else{ 
                                    this.edit.members = [];
                                    this.edit.actualMembers = [];
                                    this.edit.admin = [];
                                    this.edit.actualAdmins = [];
                                }
                                this.edit.loading = false;
                            });

                    });
                }
            },
        },
        mounted() {
            this.mobile = window.innerWidth <= 920 ? true : false;
            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() { 
            window.removeEventListener('resize', this.onResize); 
        },
        async created(){
            await this.updateButtonProperties();
        }
    }
</script>

<style scoped>

</style>
<style>
.v-stepper--alt-labels .v-stepper-item{
    flex-basis: 0px;
}
</style>