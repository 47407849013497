<template>
    <v-expansion-panels :model-value="value">
        <v-expansion-panel v-for="(item,i) in items" :key="i">
            <v-expansion-panel-title>
                {{item}}
            </v-expansion-panel-title>
            <v-expansion-panel-text class="text-body-2">
                <slot :name="i" />
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    export default {
        props: ['items', 'value'],
        data: function(){
            return {
                
            }
        }
    }
</script>

<style scoped>
    .v-expansion-panel-header {
        cursor: help;
    }

    .v-expansion-panel-header:hover {
        opacity: 0.9;
    }
</style>