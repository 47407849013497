<template>
    <v-container fluid>
        <EditForm v-model="$store.loggedIn" :fields="fields" title="Benutzerdaten" api_write="users/SetMyUserData" />
        
        <v-row>
            <v-col cols="5">
                <span class="text-h6">Avatar</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-avatar size="80">
                    <v-img cover :src="$store.avatar" height="80px" width="80px" />
                </v-avatar>
            </v-col>
        </v-row>
        <v-row v-if="avatarEdit">
            <v-col>
                <v-slide-group v-model="avatar" :show-arrows="true" mandatory>
                    <v-slide-group-item v-for="i in 19" :key="i">
                        <template #default="{active, toggle}">
                            <v-btn fab elevation="0" @click="toggle" height="100%" width="80px" :disabled="active" :style="active ? 'filter: none;' : 'filter: grayscale(0.8);'" >
                                <v-img cover :src="'https://teampoll-backend-staging.uni-trier.de/img/avatars/pic'+i+'.png'" height="80px" width="80px" />
                            </v-btn>
                        </template>
                    </v-slide-group-item>
                </v-slide-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn v-show="!avatarEdit" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="avatarEdit = true"><v-icon start>mdi-pencil</v-icon> Bearbeiten</v-btn>
                <v-btn v-show="avatarEdit" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="saveAvatar()" :loading="loading" :disabled="avatar == null || loading"><v-icon start>mdi-content-save</v-icon> Speichern</v-btn>
                <v-btn v-show="avatarEdit" color="error" dark class="ma-2 text-subtitle-2 text-capitalize" @click="avatarEdit = false;avatar=null;" :disabled="loading"><v-icon start>mdi-close</v-icon> Verwerfen</v-btn>
            </v-col>
        </v-row>
        
        <v-dialog v-model="confirmDelete" max-width="22%">
            <v-card>
                <v-card-title class="text-h5">
                    Account löschen?
                </v-card-title>
                <v-card-text>
                    <p>
                        Sie sind im Begriff Ihren Account <b>unwiderruflich</b> zu löschen. Alle Personenbezogenen Daten werden entfernt.
                    </p>
                    <v-checkbox v-model="confirmDeleteCheck" label="Hiermit bestätige ich, dass mein Account endgültig gelöscht werden soll"></v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" variant="text" @click="deleteAccount()" :disabled="!confirmDeleteCheck">Account löschen</v-btn>
                    <v-btn color="green" variant="text" @click="confirmDelete = false">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <EditTable title="Zugewiesene Gruppen" :headers="cols" api_load="groups/getmygroups" nofilter />
        <v-row>
            <v-col cols="5">
                <span class="text-h6">Account</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="error" class="ma-2 text-subtitle-2 text-capitalize" @click="confirmDelete = true">Account löschen</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup>
import EditForm from '../../components/EditForm.vue'
import EditTable from '../../components/EditTable.vue'
</script>
<script>
    export default {
        data: function (){
            return {
                fields: [
                    { text: 'E-Mail', var: 'email', value: this.$store.userData.email, disabled: true },
                    { text: 'Titel', var: 'title', value: this.$store.userData.title, disabled: this.$store.userData.isLdapUser },
                    { text: 'Vorname', var: 'firstname', value: this.$store.userData.firstname, disabled: this.$store.userData.isLdapUser},
                    { text: 'Nachname', var: 'lastname', value: this.$store.userData.lastname, disabled: this.$store.userData.isLdapUser }
                ],
                cols: [
                    { title: 'Name', align: 'start', value: 'name' },
                    { title: 'Beschreibung', value: 'description' },
                    { title: 'Admin', value: 'isAdmin', type: 'text' },
                ],
                avatarEdit: false,
                avatar: null,
                loading: false,
                confirmDelete: false,
                confirmDeleteCheck: false,
            }
        },
        methods: {
            saveAvatar(){
                this.loading = true;
                this.call('users/SetDefaultAvatar', {avatarnr: this.avatar+1}).then(res => {
                    if(res.success){
                        this.loading = false;
                        this.avatarEdit = false;
                        this.loadAvatar();
                    }
                    else {
                        this.error(res.msg);
                    }
                });
            },
            deleteAccount(){
                this.call('users/DeleteUser').then(res => {
                    if(res.success){
                        this.$store.loggedIn = false;
                        this.$router.push('/login');
                    }
                    else {
                        this.error(res.msg);
                    }
                });
            }
        },
        mounted() {
        },
        created() {
        }
    }
</script>

<style scoped>

</style>